<template>
  <div>
    <div v-if="nomore"
         class="nomore">暂无更多</div>
    <el-checkbox :indeterminate="isIndeterminate"
                 v-model="checkAll"
                 v-if="!nomore"
                 @change="handleCheckAllChange">本案进程</el-checkbox>
    <div class="items"
         style="margin-top:20px;"
         v-for="(item,index) in cities"
         :key="index">
      <el-checkbox v-model="item.flag">
        <span :style="{'font-weight':item.type ? 'bold' : ''}">{{item.pub_date}}</span>
        <span @click.stop="jump(item.doc_id)"
              :style="{'margin-left':'15px','font-weight':item.type ? 'bold' : ''}">{{item.title}}</span>
      </el-checkbox>
    </div>
  </div>
</template>

<script>
import elCheckbox from './checkBox'
import { pydataBase } from '@/api/config'
export default {
  props: ['docid', 'data'],
  model: {
    prop: 'data',
    event: 'update'
  },
  components: { elCheckbox },
  data () {
    return {
      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: false,
      nomore: false
    }
  },
  methods: {
    cancel () {
      this.checkAll = false
      this.isIndeterminate = false
      this.cities.forEach((item, index) => {
        this.$set(this.cities[index], 'flag', false)
      })
    },
    jump (docid) {
      window.open(`${window.document.location.origin}/search?docid=${docid}`)
    },
    handleCheckAllChange (val) {
      if (val) {
        this.cities.forEach((item, index) => {
          this.$set(this.cities[index], 'flag', true)
        })
        setTimeout(() => {
          this.isIndeterminate = false
          this.checkAll = true
        })
      } else {
        const index = this.cities.findIndex((item) => {
          return !item.flag
        })
        if (index === -1) {
          this.cities.forEach((item, index) => {
            this.$set(this.cities[index], 'flag', false)
          })
        }
      }
    },
    resolveData (val) {
      const $data = []
      val.forEach((item) => {
        if (item.flag) {
          $data.push({
            name: '',
            text: item.title,
            docid: item.doc_id,
            type: 0,
            children: []
          })
        }
      })
      return $data
    }
  },
  mounted () {
    this.$emit('getModuleName', '本案进程')
    this.$axios.get(`${pydataBase}/api/doc/cal_process?doc_id=${this.docid}`).then((res) => {
      if (res.data.result.length === 0) { this.nomore = true; return }
      res.data.result.map((item) => {
        item.flag = false
        return item
      })
      this.cities = res.data.result
    })
  },
  watch: {
    cities: {
      deep: true,
      handler (val) {
        let index = this.cities.findIndex((item) => {
          return item.flag
        })
        if (index > -1) {
          this.isIndeterminate = true
          this.checkAll = false
        }
        index = this.cities.findIndex((item) => {
          return !item.flag
        })
        if (index === -1) {
          this.isIndeterminate = false
          this.checkAll = true
        }
        this.$emit('update', this.resolveData(val))
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.items
  margin-top 20px
.nomore
  text-align center
</style>
