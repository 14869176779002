var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.nomore
        ? _c("div", { staticClass: "nomore" }, [_vm._v("暂无更多")])
        : _vm._e(),
      !_vm.nomore
        ? _c(
            "el-checkbox",
            {
              attrs: { indeterminate: _vm.isIndeterminate },
              on: { change: _vm.handleCheckAllChange },
              model: {
                value: _vm.checkAll,
                callback: function ($$v) {
                  _vm.checkAll = $$v
                },
                expression: "checkAll",
              },
            },
            [_vm._v("本案进程")]
          )
        : _vm._e(),
      _vm._l(_vm.cities, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "items",
            staticStyle: { "margin-top": "20px" },
          },
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: item.flag,
                  callback: function ($$v) {
                    _vm.$set(item, "flag", $$v)
                  },
                  expression: "item.flag",
                },
              },
              [
                _c(
                  "span",
                  { style: { "font-weight": item.type ? "bold" : "" } },
                  [_vm._v(_vm._s(item.pub_date))]
                ),
                _c(
                  "span",
                  {
                    style: {
                      "margin-left": "15px",
                      "font-weight": item.type ? "bold" : "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.jump(item.doc_id)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
              ]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }