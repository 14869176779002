<template>
  <div class="law-content">
    <van-tabs v-model="active">
      <van-tab
        v-for="(item, index) in datas"
        :title="`${item.name}(${item.len})`"
        :key="index"
      ></van-tab>
    </van-tabs>
    <div class="laws" v-if="!watch" @scroll="loaded($event)">
      <label v-if="data">
        <div class="item" v-for="(item, index) in data" :key="index">
          <label @click="changeChildren(item.flag, index)">
            <el-checkbox v-model="item.flag">
              <div class="law-title" @click="ToDetail(item.law_id)">
                {{ item.name }}
              </div>
            </el-checkbox>
          </label>
          <el-checkbox v-for="(iter, num) in item.data" :key="num" v-model="iter.flag">
            <div class="law-pre" @click="ToDetail(item.law_id, iter.title)">
              {{ iter.title }}
            </div>
            <div class="law-text">{{ iter.text }}</div>
          </el-checkbox>
        </div>
      </label>
      <load :state="load" />
    </div>
    <div
      class="frame"
      style="-webkit-overflow-scrolling: touch; overflow: auto; overflow: auto"
      v-if="watch"
    >
      <iframe :src="src" qc-id="page" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { pydataBase } from '@/api/config'
import elCheckbox from './checkBox'
import load from './load'
export default {
  props: ['docid', 'Data'],
  model: {
    prop: 'Data',
    event: 'updatedata',
  },
  components: { load, elCheckbox },
  data() {
    return {
      active: 0,
      checkAll: false,
      source: null,
      offset: 1,
      datas: [],
      load: 0,
      data: [],
      Loaded: false,
      src: '',
      watch: false,
      hatin: true,
    }
  },
  methods: {
    cancel() {
      this.data.forEach((item, index) => {
        item.data.forEach((item, num) => {
          this.$set(item, 'flag', false)
        })
        this.$set(item, 'flag', false)
      })
    },
    loaded(e) {
      if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
        this.offset++
        this.getData()
      }
    },
    ToDetail(lawid, title) {
      this.src = `${window.document.location.origin}/search?lawid=${lawid}&catlog=${title}`
      this.watch = true
      window.onmessage = (e) => {
        if (e.origin === window.document.location.origin && e.data === 'finish') {
          this.watch = false
        }
        window.onmessage = null
      }
    },
    changeChildren(flag, index) {
      this.Loaded = true
      if (flag) {
        this.datas[this.active].data[index].data.forEach((item) => {
          this.$set(item, 'flag', true)
        })
      } else {
        const Index = this.datas[this.active].data[index].data.findIndex((item) => {
          return !item.flag
        })
        if (Index === -1) {
          this.datas[this.active].data[index].data.forEach((item) => {
            this.$set(item, 'flag', false)
          })
        } else {
          if (
            this.datas[this.active].data[index].data.filter((item) => {
              return !item.flag
            }).length === this.datas[this.active].data[index].data.length
          ) {
            this.$set(this.datas[this.active].data[index], 'flag', false)
          } else {
            this.$set(this.datas[this.active].data[index], 'flag', true)
          }
        }
      }
      this.Loaded = false
    },
    getData() {
      this.load = 0
      if (!this.hatin) {
        return false
      }
      this.hatin = false
      if (this.source) {
        this.source.cancel()
      }
      this.source = this.$axios.CancelToken.source()
      this.$axios
        .post(`${pydataBase}/api/doc/cal_law`, {
          cancelToken: this.source.token,
          doc_id: this.docid,
          offset: this.offset,
        })
        .then((res) => {
          this.hatin = true
          if (res.data.result.datas.length === 0) {
            this.load = 1
            return
          }
          if (res.data.result.datas[0].values.length === 0) {
            this.load = 1
            return
          }
          this.load = 2
          res.data.result.datas.forEach((item) => {
            item.values.map((item) => {
              // 增加flag属性
              item.flag = false
              item.data.forEach((item) => {
                item.flag = false
                return item
              })
              return item
            })
            const index = this.datas.findIndex((iter) => {
              return iter.name === item.name
            })
            if (index === -1) {
              const len = item.values.reduce((pre, cur) => {
                return pre + cur.data.length
              }, 0)
              this.datas.push({
                name: item.name,
                data: item.values,
                len,
              })
            } else {
              const data = this.datas[index].data.concat(item.values)
              const len = data.reduce((pre, cur) => {
                return pre + cur.data.length
              }, 0)
              this.$set(this.datas[index], 'data', data)
              this.$set(this.datas[index], 'len', len)
            }
          })
          this.data = this.datas[this.active].data
          console.log(this.datas, this.data, 'datas res==============>>>>')
          this.$emit('getModuleName', this.datas[this.active].name)
        })
        .catch((res) => {
          this.load = 1
        })
    },
    reslove(val) {
      const data = []
      val.forEach((item, index) => {
        if (item.flag) {
          data.push({
            name: item.name,
            text: '',
            lawid: item.law_id,
            type: 0,
            children: [],
          })
          item.data.forEach((iter) => {
            if (iter.flag) {
              data[data.length - 1].children.push({
                name: iter.title,
                text: iter.text,
                lawid: item.law_id,
                children: [],
                type: 4,
              })
            }
          })
        }
      })
      return data
    },
  },
  created() {
    this.getData()
  },
  watch: {
    active(val) {
      this.cancel()
      this.$emit('getModuleName', this.datas[this.active].name)
      this.data = this.datas[this.active].data
    },
    data: {
      deep: true,
      handler(val) {
        this.$emit('updatedata', this.reslove(val))
        for (let i = 0; i < val.length; i++) {
          const item = val[i]
          if (
            item.data.findIndex((iter) => {
              return iter.flag
            }) > -1
          ) {
            this.$set(item, 'flag', true)
            return
          }
        }
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.law-content
  width 100%
  height 100%
  .laws
    width 100%
    height calc(100% - 50px)
    overflow auto
    padding 10px
    .law-title
      font-size 14px
      font-weight 600
.frame
  width 100%
  height 100%
  position fixed
  top 0px
  left 0px
  background-color #ffffff
  z-index 1000000000000
  iframe
    width 100%
    height 100%
</style>
