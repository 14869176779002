<template>
  <div class="list base" v-if="renderArray.length">
    <div class="firstTitle" @click="showList = !showList">
      <span :id="`detail-${title}`">{{ title }}</span>
      <span class="iconfont icon-child-off" v-if="!showList"></span>
      <span class="iconfont icon-child-on" v-if="showList"></span>
    </div>
    <div class="content" v-show="showList">
      <div class="cell" v-for="(item, index) in renderArray" :key="index">
        <div>{{ item.name }}：</div>
        <div v-if="typeof item.value === 'string'" v-html="item.value"></div>
        <div v-else>
          <div
            v-for="(detail, detailIndex) in item.value"
            :key="detailIndex"
            v-html="detail"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  props: ['array', 'title', 'type', 'titleName'],
  computed: {
    renderArray() {
      return this.array.filter(item => item.value.length)
    },
  },
  data() {
    return {
      showList: false,
    }
  },
  methods: {
    // 全部展开
    openAll() {
      this.showList = true
    },
    closeAll() {
      this.showList = false
    },
    jump(html) {
      console.log(html)
    },
  },
}
</script>
<style scoped lang="stylus">
.list
  .firstTitle
    display flex
    align-items center
    font-size 1.6em
    font-weight bold
    .iconfont
      font-size 1.4em
      color #569AF9
  .cell
    display flex
    padding 5px 0
    color #666666
    padding-left 1.4em
</style>
