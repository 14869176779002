/* eslint-disabed */
const searchWord = (function() {
  var times = 0

  function ToArray(obj) {
    return Array.prototype.slice.call(obj)
  }

  function IndexOf(str, word) {
    // console.log(str)
    // console.log(word)
    // eslint-disable-next-line no-unused-vars
    var len = word.length
    var arr = []
    // eslint-disable-next-line camelcase
    var last_i = 0
    for (var i = 0; i < str.length; i++) {
      var _str = str.slice(i, i + word.length)
      if (_str === word) {
        arr.push(str.slice(last_i, i))
        // eslint-disable-next-line camelcase
        last_i = i + word.length
      }
      if (i === str.length - 1) {
        arr.push(str.slice(last_i))
      }
    }
    return arr
  }

  searchWords.prototype.elementsTree = {}

  searchWords.prototype.times = 0
  searchWords.prototype.currentPos = 0

  searchWords.prototype.mapEach = function(content, word) {
    // console.log(content.childNodes)
    if (content.childNodes && content.childNodes.length > 0) {
      content.childNodes.forEach(item => {
        if (item.nodeType === 3) {
          if (item.nodeValue.indexOf(word) === -1) {
            return
          }
          var dv = document.createElement('div')
          var html = ''
          for (let i = 0; i < IndexOf(item.nodeValue, word).length; i++) {
            html += IndexOf(item.nodeValue, word)[i]
            if (i === IndexOf(item.nodeValue, word).length - 1) {
              break
            }
            html +=
              "<a name='" +
              times +
              "' qc-search style='background-color:" +
              (times > 0 ? 'yellow' : '#f7af43') +
              ";'>" +
              word +
              '</a>'
            times++
          }
          dv.innerHTML = html
          ToArray(dv.childNodes).forEach(_item => {
            item.parentElement.insertBefore(_item, item)
          })
          item.remove()
        }
        if (item.childNodes && item.childNodes.length > 0) {
          this.mapEach(item, word)
        }
      })
    }
  }

  searchWords.prototype.findWords = function(words, dom, word, type) {
    times = 0
    var currentPos = 0
    var _self = this
    if (word && word.trim() !== '') {
      this.mapEach(dom, word.trim())
    }
    if (words) {
      words.map(item => {
        this.mapEach(dom, item)
      })
    }
    var domList = document.querySelectorAll('a[qc-search]')
    for (var i = 0; i < domList.length; i++) {
      if (i === 0) {
        // domList[i].style.opacity = 1
        domList[i].style.backgroundColor = 'yellow'
        if (type && type === 'resetLoading') {
          domList[i].style.color = '#000'
        } else {
          domList[i].style.color = '#000'
        }
      } else {
        // domList[i].style.opacity = '.5'
        domList[i].style.backgroundColor = 'yellow'
        domList[i].style.color = '#000'
      }
      domList[i].name = i
      domList[i].id = 'search-' + i
    }
    this.times = times
    if (domList.length === 0) {
      this.currentPos = 0
    } else {
      this.currentPos = currentPos + 1
    }

    return {
      prev: function() {
        // if (times === 1) {
        //   return
        // }
        if (currentPos > 0) {
          currentPos--
        } else {
          currentPos = times - 1
        }
        ToArray(document.querySelectorAll('a[qc-search]')).forEach(function(
          item
        ) {
          // item.style.opacity = '.5'
          item.style.backgroundColor = 'yellow'
          // item.style.color = '#000'
        })
        if (document.querySelectorAll('a[qc-search]')[currentPos]) {
          const eell = document.querySelectorAll('a[qc-search]')[currentPos]
          eell.style.backgroundColor = '#f7af43'
          // eell.style.color = '#FFCC00'
        }

        _self.currentPos = currentPos + 1
        // window.document.location.href = _self.url + '#' + currentPos
        const id = 'search-' + currentPos
        document.getElementById(id).scrollIntoView()
        // console.log("document.getElementById(id).offsetTop=>")
        // const height = this.$refs.detailContainer.scrollHeight
        // this.$refs.detailContainer.scrollTo(0, height)
      },
      next: function() {
        // if (times === 1) {
        //   return
        // }
        if (currentPos < times - 1) {
          currentPos++
        } else {
          currentPos = 0
        }
        ToArray(document.querySelectorAll('a[qc-search]')).forEach(function(
          item
        ) {
          // item.style.opacity = '.5'
          item.style.backgroundColor = 'yellow'
          // item.style.color = '#000'
        })
        if (document.querySelectorAll('a[qc-search]')[currentPos]) {
          const ell = document.querySelectorAll('a[qc-search]')[currentPos]
          // ell.style.opacity = 1
          ell.style.backgroundColor = '#f7af43'
          // ell.style.color = '#FFCC00'
        }

        _self.currentPos = currentPos + 1
        // window.document.location.href = _self.url + '#' + currentPos
        const id = 'search-' + currentPos
        // console.log(id)
        if (document.getElementById(id)) {
          document.getElementById(id).scrollIntoView()
        }
        // const height = this.$refs.detailContainer.scrollHeight
        // this.$refs.detailContainer.scrollTo(0, height)
      },
      scroll: function() {
        const id = 'search-0'
        if (document.getElementById(id)) {
          document.getElementById(id).scrollIntoView({ block: 'start' })
        }
      },
      Destruction: function() {
        ToArray(document.querySelectorAll('a[qc-search]')).forEach(function(
          item
        ) {
          var prev = item.previousSibling
          var next = item.nextSibling
          var str = ''
          while (prev) {
            if (prev.nodeType === 3) {
              str += prev.nodeValue
              prev.remove()
            } else {
              break
            }
            prev = prev.previousSibling
          }
          str += item.innerText
          while (next) {
            if (next.nodeType === 3) {
              str += next.nodeValue
              next.remove()
            } else {
              break
            }
            next = next.nextSibling
          }
          item.parentElement.replaceChild(document.createTextNode(str), item)
        })
      }
    }
  }

  searchWords.prototype.getElements = function(content, offset) {
    offset.content = content
    offset.children = ToArray(content.children).map(item => {
      return { content: item, children: [] }
    })
    for (var i = 0; i < offset.children.length; i++) {
      if (offset.children[i].content.children.length > 0) {
        this.getElements(offset.children[i].content, offset.children[i])
      }
    }
  }

  function searchWords(content, url) {
    // console.log(url)
    this.url = url
    this.getElements(content, this.elementsTree)
  }

  return function(content, url) {
    // eslint-disable-next-line new-cap
    return new searchWords(content, url)
  }
})()
export default searchWord
