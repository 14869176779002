var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-content" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.datas, function (item, index) {
          return _c("van-tab", {
            key: index,
            attrs: { title: `${item.name}(${item.len})` },
          })
        }),
        1
      ),
      !_vm.watch
        ? _c(
            "div",
            {
              staticClass: "laws",
              on: {
                scroll: function ($event) {
                  return _vm.loaded($event)
                },
              },
            },
            [
              _vm.data
                ? _c(
                    "label",
                    _vm._l(_vm.data, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "item" },
                        [
                          _c(
                            "label",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChildren(item.flag, index)
                                },
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: item.flag,
                                    callback: function ($$v) {
                                      _vm.$set(item, "flag", $$v)
                                    },
                                    expression: "item.flag",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "law-title",
                                      on: {
                                        click: function ($event) {
                                          return _vm.ToDetail(item.law_id)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(item.data, function (iter, num) {
                            return _c(
                              "el-checkbox",
                              {
                                key: num,
                                model: {
                                  value: iter.flag,
                                  callback: function ($$v) {
                                    _vm.$set(iter, "flag", $$v)
                                  },
                                  expression: "iter.flag",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "law-pre",
                                    on: {
                                      click: function ($event) {
                                        return _vm.ToDetail(
                                          item.law_id,
                                          iter.title
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(iter.title) + " ")]
                                ),
                                _c("div", { staticClass: "law-text" }, [
                                  _vm._v(_vm._s(iter.text)),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("load", { attrs: { state: _vm.load } }),
            ],
            1
          )
        : _vm._e(),
      _vm.watch
        ? _c(
            "div",
            {
              staticClass: "frame",
              staticStyle: {
                "-webkit-overflow-scrolling": "touch",
                overflow: "auto",
              },
            },
            [
              _c("iframe", {
                attrs: { src: _vm.src, "qc-id": "page", frameborder: "0" },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }