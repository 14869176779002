import { render, staticRenderFns } from "./textItem.vue?vue&type=template&id=464c5225"
import script from "./textItem.vue?vue&type=script&lang=js"
export * from "./textItem.vue?vue&type=script&lang=js"
import style0 from "./textItem.vue?vue&type=style&index=0&id=464c5225&scope=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3440258-cci-46129907-392501/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('464c5225')) {
      api.createRecord('464c5225', component.options)
    } else {
      api.reload('464c5225', component.options)
    }
    module.hot.accept("./textItem.vue?vue&type=template&id=464c5225", function () {
      api.rerender('464c5225', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Ana/pages/detail/components/textItem.vue"
export default component.exports