<template>
  <div class="appeal-content">
    <div v-if="active > -1">
      <van-tabs v-model="active">
        <van-tab
          v-for="(item, index) in list"
          :key="item.index"
          :title="item.label"
          :name="index.toString()"
        >
        </van-tab>
      </van-tabs>
    </div>
    <div v-if="cities && !watch" class="itemcontent" @scroll="loaded">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        v-if="fullscreenLoading === 2"
        >全部</el-checkbox
      >
      <div class="items" v-for="(item, index) in cities" :key="index">
        <div class="left">
          <el-checkbox v-model="item.flag" />
        </div>
        <div class="right" @click="jump(item.doc_id)">
          <div>{{ item.case_name }}</div>
          <div>{{ item.case_id }}</div>
        </div>
      </div>
      <load :state="fullscreenLoading"></load>
    </div>
    <div
      class="frame"
      style="-webkit-overflow-scrolling:touch;overflow:auto;overflow:auto;"
      v-if="watch"
    >
      <iframe :src="src" ref="content" qc-id="page" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { pydataBase } from '@/api/config'
import elCheckbox from './checkBox'
import load from './load'
export default {
  components: {
    elCheckbox,
    load
  },
  props: ['docid', 'data', 'name'],
  model: {
    prop: 'data',
    event: 'update'
  },
  data() {
    return {
      checkAll: false,
      watch: false,
      src: '',
      checkedCities: [],
      list: [],
      isIndeterminate: false,
      active: -1, // 模块索引
      offset: 1, // 页码
      fullscreenLoading: 0,
      cities: [],
      load: true,
      source: null,
      lastVal: 0,
      newData: null
    }
  },
  methods: {
    cancel() {
      this.checkAll = false
      this.isIndeterminate = false
      this.cities.forEach(item => {
        this.$set(item, 'flag', false)
      })
    },
    jump(docid, catlog) {
      this.src = `${
        window.document.location.origin
      }/search?docid=${docid}&back=true`
      this.watch = true
      window.onmessage = e => {
        if (
          e.origin === window.document.location.origin &&
          e.data === 'finish'
        ) {
          this.watch = false
        }
        window.onmessage = null
      }
    },
    loaded(e) {
      if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
        this.list[this.active].offset++
        this.getData(
          this.list[this.active].label,
          this.list[this.active].offset,
          this.active
        )
      }
    },
    handleCheckAllChange(val) {
      if (val) {
        this.cities.forEach((item, index) => {
          this.$set(this.cities[index], 'flag', true)
        })
        setTimeout(() => {
          this.isIndeterminate = false
          this.checkAll = true
        })
      } else {
        const index = this.cities.findIndex(item => {
          return !item.flag
        })
        if (index === -1) {
          this.cities.forEach((item, index) => {
            this.$set(this.cities[index], 'flag', false)
          })
        }
      }
    },
    getData(name, offset, val) {
      this.fullscreenLoading = 0
      if (this.source) {
        this.source.cancel()
      }
      this.source = this.$axios.CancelToken.source()
      this.$axios
        .get(`${pydataBase}/api/doc/cal_case?name=${name}&offset=${offset}`, {
          cancelToken: this.source.token
        })
        .then(res => {
          if (res.data.result.length === 0) {
            this.fullscreenLoading = 1
            return
          }
          res.data.result.map((item, index) => {
            item.flag = false
            return item
          })
          this.cities = this.cities.concat(res.data.result)
          this.fullscreenLoading = 2
          setTimeout(() => {
            this.load = false
          })
        })
    },
    resloveData(data) {
      const $data = []
      data.forEach(item => {
        if (item.flag) {
          $data.push({
            name: '',
            text: item.case_name,
            docid: item.doc_id,
            type: 0,
            children: []
          })
        }
      })
      console.log($data)
      return $data
    }
  },
  mounted() {
    this.$axios
      .get(`${pydataBase}/api/doc/cal_party?doc_id=${this.docid}`)
      .then(res => {
        if (res.data.result.length === 0) {
          this.fullscreenLoading = 1
          return
        }
        res.data.result.forEach((item, index) => {
          this.list.push({
            index: index,
            label: item,
            offset: 1
          })
        })
        this.cities = []
        setTimeout(() => {
          this.load = false
          this.active = 0
        })
      })
    this.$emit('getModuleName', '相关判例')
  },
  watch: {
    active: {
      handler(val) {
        if (this.load) {
          return
        }
        this.cities.forEach((item, index) => {
          this.$set(this.cities[index], 'flag', false)
        })
        this.checkAll = false
        this.isIndeterminate = false
        if (this.list[parseInt(this.lastVal)]) {
          this.list[parseInt(this.lastVal)].offset = 1
        }
        this.lastVal = val
        this.cities = []
        this.load = true

        this.getData(
          this.list[parseInt(val)].label,
          this.list[parseInt(val)].offset,
          val
        )
      }
    },
    cities: {
      deep: true,
      handler(val) {
        if (this.load) {
          return []
        }
        if (this.cities.length === 1) {
          if (this.cities[0].flag) {
            this.isIndeterminate = false
            this.checkAll = true
          } else {
            this.checkAll = false
          }
          this.$emit('update', this.resloveData(val))
          return
        }
        let index = this.cities.findIndex(item => {
          return item.flag
        })
        if (index > -1) {
          this.isIndeterminate = true
          this.checkAll = false
        }
        index = this.cities.findIndex(item => {
          return !item.flag
        })
        if (index === -1) {
          this.isIndeterminate = false
          this.checkAll = true
        }
        this.$emit('update', this.resloveData(val))
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
*
  margin 0px
  padding 0px
.appeal-content
  width 100%
  height 100%
  .tabs
    width 100%
    height 25px
.itemcontent
  overflow auto
  height calc(100% - 0.3rem)
  padding 5px
  padding-top 50px
  box-sizing border-box
  .items
    width 100%
    display flex
    margin-top 10px
    .left
      width 5%
      height 100%
      margin-top 3px
    .right
      width 95%
      cursor pointer
      min-height 50px
      overflow hidden
      border-bottom 1px solid #cccccc
      div:nth-child(1)
        font-size 14px
        color #333
      div:nth-child(2)
        font-size 12px
        color #AAAAAA
        margin-top 5px
        margin-bottom 5px
.frame
  width 100%
  height 100%
  position fixed
  top 0px
  left 0px
  background-color #ffffff
  z-index 1000000000000
  iframe
    width 100%
    height 100%
</style>
<style scoped>
.itemcontent > div > ::v-deep.conten {
  width: auto;
}
</style>
