<template>
  <div class="text-item">
    <div class="check-box" @click.stop="handleSelectItem">
      <van-checkbox v-model="checked" shape="square"></van-checkbox>
    </div>
    <!-- 文字 -->
    <div class="text">
      <span v-html="item" @click="addClick" @copy="handleCopy"></span>
      <span>&nbsp;&nbsp;</span>
      <i
        class="iconfont icon-fuzhi"
        @click.stop="copy(item)"
        v-show="showCopy"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['item', 'index', 'isChecked', 'textArr'],
  name: 'textItem',
  data() {
    return {
      showCopy: false, // 是否显示复制按钮
      checked: false
    }
  },
  computed: {
    ...mapState('searchReport', ['clearCheck'])
  },
  mounted() {
    this.checked = this.isChecked
    if (this.textArr.length) {
      this.textArr.forEach(val => {
        if (this.index === val.index) {
          this.checked = true
        }
      })
    }
  },
  watch: {
    // isChecked() {
    //   this.checked = this.isChecked
    //   console.log(this.isChecked, '++++isChecked+++')
    // },
    clearCheck(val) {
      if (val) {
        this.checked = false
      }
    }
  },
  methods: {
    handleCopy(e) {
      console.log(e)
      const clipboardData = e.clipboardData || window.clipboardData
      // 如果 未复制或者未剪切，直接 return
      if (!clipboardData) return
      // Selection 对象 表示用户选择的文本范围或光标的当前位置。
      // 声明一个变量接收 -- 用户输入的剪切或者复制的文本转化为字符串
      const text = window.getSelection().toString()
      console.log('text------------>>', text)
      if (text) {
        // 如果文本存在，首先取消默认行为
        e.preventDefault()
        // 通过调用 clipboardData 对象的 setData(format,data) 方法，设置相关文本
        // format 一个 DOMString 类型 表示要添加到 drag object 的拖动数据的类型
        // data 一个 DOMString 表示要添加到 drag object 的数据
        clipboardData.setData('text/plain', text)
        this.$emit('copy', text)
      }
    },
    copy(str) {
      // const str = e.target.parentElement.querySelector('.text').innerText
      // const str = this.Ptitle + '\n' + this.item
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中'
      })
      setTimeout(() => {
        this.showCopy = false
      }, 1000)
    },
    // 点击红色文字出现弹框，点击其他区域复制按钮出现或隐藏
    addClick(e) {
      // console.log(e)
      if (e.target && e.target.id === 'red') {
        this.$emit('addClick', e)
      } else {
        this.showCopy = !this.showCopy
      }
    },
    handleSelectItem() {
      this.checked = !this.checked
      this.$emit('select', {
        flag: this.checked,
        text: this.item,
        index: this.index
      })
    }
  }
}
</script>

<style scope lang="stylus">
.text-item
  position relative
  .check-box
    position absolute
    left 0
    top 0
    z-index 99
    width 30px
    height 30px
    display flex
    justify-content center
    align-items center
    .van-checkbox__icon
      .van-icon
        font-size 8px
        line-height 30px
  .text
    text-indent 30px
    line-height 30px
  & .icon-fuzhi
    opacity 0.5
    font-size 14px
    cursor pointer
    color #ccc
</style>
