var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list fs" },
    [
      _c(
        "div",
        { staticClass: "firstTitle", on: { click: _vm.showListHandle } },
        [
          _c("span", { attrs: { id: `detail-${_vm.title}` } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          !_vm.showList
            ? _c("span", { staticClass: "iconfont icon-child-off" })
            : _vm._e(),
          _vm.showList
            ? _c("span", { staticClass: "iconfont icon-child-on" })
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showList,
              expression: "showList",
            },
          ],
          staticClass: "content contentHeight",
        },
        _vm._l(_vm.array, function (item, index) {
          return _c("list-item", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  (item.values && item.values.length) ||
                  (item.value && item.value.length),
                expression:
                  "(item.values && item.values.length) || (item.value && item.value.length)",
              },
            ],
            key: item.name + index,
            ref: "listItem",
            refInFor: true,
            attrs: {
              nameArr: _vm.nameArr,
              clearCheck: _vm.clearCheck,
              listItem: item,
              titleName: _vm.titleName,
            },
            on: { collect: _vm.emitCollect, copy: _vm.handleCopy },
          })
        }),
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { closeable: "", round: "" },
          model: {
            value: _vm.showBuyVipPopup,
            callback: function ($$v) {
              _vm.showBuyVipPopup = $$v
            },
            expression: "showBuyVipPopup",
          },
        },
        [
          _c("div", { staticClass: "popupBox" }, [
            _c("img", {
              staticStyle: { position: "absolute", left: "19vw", top: "-80px" },
              attrs: {
                src: require("../../../../../../public/img/popup.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "hyzs" }, [_vm._v("会员专属")]),
            _c("div", { staticClass: "kthy" }, [
              _vm._v("开通律呗会员即可享用大数据功能！"),
            ]),
            _c(
              "div",
              { staticClass: "vipBtn" },
              [
                _c(
                  "van-button",
                  {
                    attrs: { block: "", type: "info", size: "normal" },
                    on: { click: _vm.toBuyVipPage },
                  },
                  [_vm._v("立即开通")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      !_vm.isShowAll
        ? _c("div", [
            _c("div", { staticClass: "mask" }),
            _c(
              "div",
              {
                staticClass: "clickShowAllBtn",
                on: {
                  click: function ($event) {
                    _vm.showBuyVipPopup = true
                  },
                },
              },
              [
                _vm._v("点击查看剩余全文 "),
                _c("van-icon", { attrs: { name: "arrow-down" } }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }