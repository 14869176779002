var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-item" }, [
    _c(
      "div",
      {
        staticClass: "check-box",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleSelectItem.apply(null, arguments)
          },
        },
      },
      [
        _c("van-checkbox", {
          attrs: { shape: "square" },
          model: {
            value: _vm.checked,
            callback: function ($$v) {
              _vm.checked = $$v
            },
            expression: "checked",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "text" }, [
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.item) },
        on: { click: _vm.addClick, copy: _vm.handleCopy },
      }),
      _c("span", [_vm._v("  ")]),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCopy,
            expression: "showCopy",
          },
        ],
        staticClass: "iconfont icon-fuzhi",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.copy(_vm.item)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }