var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c(
        "div",
        { staticClass: "thread" },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "items",
              style: {
                "border-left-color":
                  index === _vm.data.length - 1 ? "transparent" : "",
              },
              on: {
                click: function ($event) {
                  return _vm.jump(item.doc_id)
                },
              },
            },
            [
              _c("div", { staticClass: "text" }, [
                _c("div", [_vm._v(_vm._s(item.pub_date))]),
                _c(
                  "div",
                  { style: { "font-weight": item.type ? "600" : "" } },
                  [_vm._v(_vm._s(item.title))]
                ),
              ]),
              _c("div", { class: [item.type ? "cirl-big" : "cirl"] }, [
                _vm._v(_vm._s(item.type)),
              ]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }