<template>
  <div id="precedentDetail" class="precedentDetail highlight-area" ref="detailContainer" @scroll="taggleBottomScroll">
    <div v-show="!skydriveif" id="precedentWrapper">
      <fb-header slot="header" :customer="true" v-if="showTitleBar" :title="title" @clickTitle="clickTitle" fixed>
        <fb-button slot="left" icon="back" size="small" type="primary" @click="clickback" v-if="!this.$route.query || !this.$route.query.docid || (this.$route.query && this.$route.query.back)">
          返回
        </fb-button>
        <span class="copy" slot="copy" style="flex: 1">
          <i class="iconfont icon-fuzhi" @click="copyTitle" v-show="showCopy"></i>
        </span>
        <!-- 菜单 -->
        <fb-button slot="right" icon="menu" size="small" type="primary" @click.stop.capture="liste">
          <template name="right">
            <div class="menu" v-if="collect" ref="politre">
              <div @click="download">分享</div>
              <div @click="collection" v-if="!this.$route.query || !this.$route.query.docid">收藏</div>
              <div @click="download2" v-if="ua !== 'fb-iOS-Webview' && (!this.$route.query || !this.$route.query.docid) && !$route.query.isMiniApp">下载</div>
              <div v-if="userId" @click="collectReport">收集</div>
            </div>
          </template>
        </fb-button>
      </fb-header>
      <van-loading v-if="loadPage || loading" type="spinner" size="70px"></van-loading>
      <van-list v-model="loading" :finished="finished">
        <div class="container" ref="detailContainer">
          <detail
            @changcheck="handleCheck"
            :pageInfo="pageInfo"
            :words="words"
            :flag="haha"
            :forword="forword"
            :titleName="titleName"
            :title.sync="title"
            :toCatloag.sync="toCatloag"
            :scrollDocument="$refs.detailContainer"
            @clickBack="clickback"
            @resetLoading="resetLoading"
            :is_above="is_above"
            @getname="getname"
            @updatePageInfo="updatePageInfo"
            :toZYJD="toZYJD"
            ref="content"
          />
          <bottom-tip :visible="bottomTipVisible" :showLimit.sync="showLimit" />
        </div>
      </van-list>
      <div class="CaseInAna" v-if="!haha">
        <div class="iconfonts zhiding" style="display: none" @click="clickZhiding" ref="zhiding">
          <span class="iconfont icon-zhiding"></span>
        </div>
        <div class="CaseInAnaList" style="">
          <div
            v-show="reportCount"
            class="iconfonts collect"
            :style="{
              background: isCollect ? '#f4a83b' : '',
              position: 'relative',
            }"
            @click.stop="handleCollect()"
          >
            <transition name="slide-fade">
              <span class="badge-box" v-show="showAdd"> +1 </span>
            </transition>
            <transition name="slide-fade">
              <span class="badge-box" v-show="showReduce"> -1 </span>
            </transition>
            <span>集</span>
            <span class="count">{{ this.reportCount }}</span>
          </div>
          <div class="iconfonts mulu" v-for="(item, index) in approvalData" :key="index" :style="{ background: item.doc_id === doc_id ? '#f4a83b' : '' }" @click.stop="obtainRound(item.doc_id)">
            <span class="iconfont">{{ item.round.substr(0, 1) }}</span>
          </div>
        </div>
        <!-- <div class="iconfonts mulu"
             :style="{background: this.currentTitle.round === '再审' ? '#f4a83b':''}"
             v-show="approvalState.approvalFree === '再' ? true: false"
             @click.stop="obtainRound(approvalState.approvalFreeId)">
          <span class="iconfont">再</span>
        </div> -->
        <div class="iconfonts mulu" @click="clickShow">
          <span class="iconfont icon-zhediexiaoguo1"></span>
        </div>
        <!-- 搜索  -->
        <div class="iconfonts fanhui" @click="clickShowSearchBar" v-if="!searchWordsShow">
          <span class="iconfont icon-sousuo"></span>
        </div>
        <div class="iconfonts fanhui" v-else style="visibility: hidden">
          <span class="iconfont icon-sousuo"></span>
        </div>
      </div>
      <div v-if="haha" class="skyDrive">
        <skyDrive
          :listid="data.length > 0 ? data : id"
          :Type="name ? (name === 'relelaw' ? 'LAW_FRAGMENT' : 'LEGAL_PRECEDENT_FRAGMENT') : 'docid'"
          @CancelCollect="close"
          :listname="Title"
          :modulename="Title"
          @getId="getId"
        ></skyDrive>
      </div>
      <div class="search-words" v-show="searchWordsShow">
        <div class="words">
          <div v-for="(item, i) in wordsList" :key="i">{{ item }}<i class="iconfont icon-clear" @click="deleteWord(i)"></i></div>
        </div>
        <div class="input">
          <span style="color: #409eff; margin-right: 10px" @click="closeSearch">退出</span>
          <div style="flex: 1">
            <van-field v-model="value" placeholder="请输入关键词" @input="handleChange" @keyup.native="hanldeKeySpace" @keyup.enter="hanldeKeyEnter" @blur="handleBlur" ref="searchKeyWordInput" />
          </div>
          <div style="margin: 0 5px">
            <div style="color: #409eff">{{ currentPos }}</div>
            <div style="color: #ccc">{{ times }}</div>
          </div>
          <van-icon name="arrow-down" @click="optNext" />
          <van-icon name="arrow-up" @click="optPrev" />
        </div>
      </div>
      <div class="contents" :style="{ right: showLeftPanel ? '0px' : '-100%' }">
        <div @click="name = 'likeappeal'">
          <span>相似判例</span>
          <i class="iconfont icon-child-off"></i>
        </div>
        <div @click="name = 'relelaw'">
          <span>相关法律法规</span>
          <i class="iconfont icon-child-off"></i>
        </div>
        <div @click="name = 'releappeal'">
          <span>相关判例</span>
          <i class="iconfont icon-child-off"></i>
        </div>
        <!-- <div @click="name = 'releappeal'">
        <span>同案由判例</span>
        <i class="iconfont icon-child-off"></i>
      </div>
      <div @click="name = 'releappeal'">
        <span>上级法院同案由判例</span>
        <i class="iconfont icon-child-off"></i>
      </div>
      <div @click="name = 'releappeal'">
        <span>同案由指导、公报、典型案例</span>
        <i class="iconfont icon-child-off"></i>
      </div>
      <div @click="name = 'releappeal'">
        <span>相关知识</span>
        <i class="iconfont icon-child-off"></i>
      </div> -->
        <!-- <div @click="name='flows'">
        <span>本案进程</span>
        <i class="iconfont icon-child-off"></i>
      </div> -->
        <flow />
      </div>
      <div class="z_z" :style="{ left: showLeftPanel ? '0px' : '-100%' }" @click="showLeftPanel = false"></div>
      <div class="otherModule" :style="{ left: name.length > 0 ? '0px' : '-100%' }">
        <div class="heade">
          <i class="iconfont icon-back" @click="name = ''"></i>
          <span class="title">{{ name === 'flows' ? '案件进程' : name === 'relelaw' ? '相关法律法规' : name === 'likeappeal' ? '相似判例' : '相关判例' }}</span>
          <div @click="save" class="text" v-if="!this.$route.query || !this.$route.query.docid">收藏</div>
          <div @click="share" class="text" v-if="!this.$route.query || !this.$route.query.docid">分享</div>
        </div>
        <div class="body">
          <component :is="name" :docid="id" v-model="data" @getModuleName="setName" ref="tabs"></component>
        </div>
      </div>
    </div>
    <div v-if="skydriveif" class="sky-drive-if">
      <sky-drive :skydriveif="skydriveif" @close="closesky"></sky-drive>
    </div>
    <fb-nonmember :showLimit.sync="showLimit" />
    <div class="app-download-tip" v-if="isPublicRouteDetail">
      <download-app />
    </div>
    <div v-if="showReport" class="collect-report">
      <searchReport @close="closeReport" from="precedent" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { caseBase, pydataBase } from '~api-config'
import detail from '../Ana/pages/detail'
import taggleBottomMixin from '../Search/taggleBottomMixin'
import flow from './flow'
import flows from './tabs/flows'
import relelaw from './tabs/relelaw'
import likeappeal from './tabs/likeappeal'
import releappeal from './tabs/releappeal'
import searchWord from '../Search/compoments/searchword'
import nonmemberMixin from './nonmemberMixin'
// import skyDrive from '@/pages/skyDrive/index'
// import searchReport from '@/pages/transform/searchReport'
export default {
  name: 'precedent-detail',
  components: {
    skyDrive: () => import('@/pages/skyDrive/index'),
    searchReport: () => import('@/pages/transform/searchReport'),
    detail,
    flow,
    flows,
    relelaw,
    likeappeal,
    releappeal,
  },
  props: ['id', 'words', 'titleName', 'forword', 'section', 'toZYJD', 'toCatloag'],
  mixins: [taggleBottomMixin, nonmemberMixin],
  data() {
    return {
      showLimit: false,
      showCopy: false, // 是否显示复制按钮
      contentArr: [], // 正文报告
      doc_id: '',
      pageId: '', // 页面路由携带的id
      approvalData: [],
      currentTitle: '',
      approvalState: {
        approvalOne: '',
        approvalOneId: '',
        approvalTwo: '',
        approvalTwoId: '',
        approvalFree: '',
        approvalFreeId: '',
      },
      isCollect: false, // 是否收集加入报告
      showAdd: false, // 是否显示+1
      showReduce: false, // 是否显示-1
      lastCount: 0, // 检索报告的数值
      showReport: false,
      data: [],
      numid: 0,
      preview: false,
      name: '',
      options: false,
      showTitleBar: true,
      showSearchBar: false,
      loading: false,
      loadPage: false, // 页面加载中loading
      finished: true,
      title: '',
      pageInfo: {
        id: '',
      },
      showLeftPanel: false,
      show: false,
      active: 0,
      is_above: false,
      collect: false,
      Title: '',
      haha: false,
      skydriveif: false,
      BindMethods: {
        start: null,
        End: null,
        moveflag: false,
      },
      searchOpt: null,
      wordsList: [],
      opt: {
        prev: function () {},
        next: function () {},
        des: null,
      },
      value: '',
      searchWordsShow: false,
      times: 0,
      currentPos: 0,
      clickCollect: false,
    }
  },
  computed: {
    ...mapState('nonmemberLimit', ['isValid']),
    ...mapGetters('searchReport', ['reportCount']),
    ...mapGetters('user', ['userId', 'userinfo']),
    ...mapState('searchReport', ['judgeReports', 'lawReports', 'criterionReports', 'contentReports', 'knowledgeReports']),
    bottomTipVisible() {
      if (this.loadPage || this.loading) {
        return false
      } else {
        // if (this.isValid) {
        //   return false
        // }
        return this.$store.state.user.userinfo && this.$store.state.user.userinfo.memberStatus !== 1 && this.$store.state.user.userinfo.restNum <= 0 && !this.$route.query.isMiniApp
      }
    },
    ua() {
      return navigator.userAgent
    },
    isPublicRouteDetail() {
      return this.$route.path.startsWith('/transform/searchKeepAlive/')
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.docid) {
      this.pageId = this.$route.query.docid
    }
    let x = 0
    this.$refs.detailContainer.addEventListener('scroll', this.handleScroll)
    this.BindMethods.start = (e) => {
      x = e.touches[0].pageX
      if (x < document.documentElement.offsetWidth * 0.3) {
        this.BindMethods.moveflag = true
      }
    }
    this.BindMethods.End = (e) => {
      if (e.changedTouches[0].pageX - x < -100) {
        this.showLeftPanel = true
      }
      this.BindMethods.moveflag = false
    }
    this.$refs.detailContainer.addEventListener('touchstart', this.BindMethods.start, true)
    this.$refs.detailContainer.addEventListener('touchend', this.BindMethods.End, true)
    const id = this.id || this.pageId
    this.approvalAxios(id)
    this.contentArr = JSON.parse(JSON.stringify(this.contentReports)) || []
    window.console.log('index userinfo', this.$store.state.user.userinfo)
    if (this.userId) {
      if (this.$store.state.user.userinfo && this.$store.state.user.userinfo.memberStatus !== 1 && this.$store.state.user.userinfo.restNum <= 0 && !this.$route.query.isMiniApp) {
        console.log('登陆非会员滚动到底部 index')
        this.showLimit = true
      } else {
        this.showLimit = false
      }
    } else {
      this.showLimit = false
    }
    setTimeout(() => {
      const toCatloagEl = document.getElementById(`detail-${this.toCatloag}`)
      if (!this.showLimit && toCatloagEl) {
        toCatloagEl.scrollIntoView({ behavior: 'smooth' })
      }
    }, 500)
  },
  methods: {
    ...mapActions('searchReport', ['setStampReport']),
    ...mapMutations('searchReport', ['SET_CONTENT_REPORT', 'SET_CLEAR_CHECK']),
    clickTitle() {
      this.showCopy = !this.showCopy
    },
    copyTitle() {
      // console.log(this.title)
      const str = this.title
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      setTimeout(() => {
        this.showCopy = false
      }, 1000)
    },
    blur(value) {
      if (value !== '') {
        if (this.opt.Destruction) {
          this.opt.Destruction()
          this.opt.Destruction = null
        }
        this.wordsList.push(value)
        this.value = ''
        this.opt = this.searchOpt.findWords(this.wordsList, this.$refs.content.$refs.target, this.value)
        this.times = this.searchOpt.times
        this.currentPos = this.searchOpt.currentPos
      }
    },
    deleteWord(i) {
      if (this.opt.Destruction) {
        this.opt.Destruction()
        this.opt.Destruction = null
      }
      this.wordsList.splice(i, 1)
      this.opt = this.searchOpt.findWords(this.wordsList, this.$refs.content.$refs.target, this.value)
      this.times = this.searchOpt.times
      this.currentPos = this.searchOpt.currentPos
      if (this.wordsList.length === 0) {
        if (this.opt.Destruction) {
          this.opt.Destruction()
          this.opt.Destruction = null
        }
      }
    },
    closeSearch() {
      this.times = 0
      this.currentPos = 0
      this.wordsList = []
      this.searchWordsShow = false
      if (this.opt.Destruction) {
        this.opt.Destruction()
        this.opt.Destruction = null
      }
    },
    handleChange(value) {
      if (this.value.trim().length === 0) {
        this.value = ''
      }
      if (this.opt.Destruction) {
        this.opt.Destruction()
        this.opt.Destruction = null
      }
      this.opt = this.searchOpt.findWords(this.wordsList, this.$refs.content.$refs.target, this.value)
      this.times = this.searchOpt.times
      this.currentPos = this.searchOpt.currentPos
    },
    hanldeKeySpace(e) {
      if (this.value.trim() !== '' && this.value.length && this.value.indexOf(' ') > -1) {
        var key = this.value.trim()
        this.blur(key)
        this.$nextTick(() => {
          this.value = ''
        })
      }
    },
    optPrev() {
      if (this.value !== '') {
        this.blur(this.value.trim())
      }
      this.opt.prev()
      this.currentPos = this.searchOpt.currentPos
    },
    optNext() {
      if (this.value !== '') {
        this.blur(this.value.trim())
      }
      this.opt.next()
      this.currentPos = this.searchOpt.currentPos
    },
    hanldeKeyEnter(e) {
      if (this.value === '') {
        this.opt.next()
        this.currentPos = this.searchOpt.currentPos
      } else {
        this.blur(this.value.trim())
      }
    },
    obtainRound(id) {
      this.doc_id = id
      this.$bus.$emit('obtainRound', id)
    },
    // 高亮关键词
    highlighKeyword() {
      this.$emit('highlight')
    },
    // 获取审批信息
    approvalAxios(id) {
      this.doc_id = id
      this.approvalData = []
      // 接口调用成功
      this.loadPage = true
      this.$axios
        .get(`${pydataBase}/api/doc/cal_process?doc_id=${this.id || this.pageId}`)
        .then((res) => {
          if (res.data.code === 200) {
            res.data.result.forEach((item) => {
              if (item.doc_id !== '' && item.round !== '') {
                this.approvalData.push(item)
              }
            })
            if (this.approvalData.length < 2) {
              this.approvalData = []
            }
          }
          setTimeout(() => {
            this.$emit('highlight')
          }, 500)
          this.loadPage = false
        })
        .catch((err) => {
          this.loadPage = false
          console.log(err)
        })
    },
    setName(name) {
      this.Title = name
    },
    save() {
      if (this.data.length === 0) {
        this.$notify('请选择收藏文件')
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
        return
      }
      this.haha = true
    },
    // 点击分享触发事件
    share() {
      const date = new Date()
      const url = `${window.location.origin}/transform/searchKeepAlive/precedentDetail?docid=${this.id || this.pageId}`
      console.log('分享的地址', url)
      const ua = navigator.userAgent
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${this.selfKey === 'evt' ? '证据名称' : '争议焦点'}》`,
          '律呗判例',
          window.encodeURI(
            url
            // `https://view.officeapps.live.com/op/view.aspx?src=${
            //   res.data.data.path
            // }`
          )
        )
      } else if (ua === 'fb-iOS-Webview') {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${
              this.selfKey === 'evt' ? '证据名称' : '争议焦点'
            }》`,
            url: window.encodeURI(
              url
              // `https://view.officeapps.live.com/op/view.aspx?src=${
              //   res.data.data.path
              // }`
            ),
          })
        )
      } else if (ua === 'fb-flutter-Webview') {
        try {
          window.shareCaseFile.postMessage(
            JSON.stringify({
              filename: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${
                this.selfKey === 'evt' ? '证据名称' : '争议焦点'
              }》`,
              url: window.encodeURI(
                url
                // `https://view.officeapps.live.com/op/view.aspx?src=${
                //   res.data.data.path
                // }`
              ),
            })
          )
        } catch (error) {
          console.log('shareCaseFile.postMessage', error)
        }
      } else {
        const text = `${window.location.origin}/transform/searchKeepAlive/precedentDetail?docid=${this.id || this.pageId}`
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已将信息复制到粘贴板，长按粘贴。',
        })
      }
    },
    // 点击分享事件
    download() {
      // console.log('点击分享事件', this.id, this.pageId)

      const docid = this.id || this.pageId
      console.log('docid', docid)
      const url = `${window.location.origin}/transform/searchKeepAlive/precedentDetail?docid=${docid}`
      console.log('分享的地址22', url)
      const ua = navigator.userAgent
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(this.title, '律呗判例大数据', window.encodeURI(url))
      } else if (ua === 'fb-iOS-Webview') {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: this.title,
            description: '律呗判例大数据',
            url: window.encodeURI(url),
          })
        )
      } else if (ua === 'fb-flutter-Webview') {
        try {
          window.shareCaseFile.postMessage(
            JSON.stringify({
              filename: this.title,
              description: '律呗判例大数据',
              url: window.encodeURI(url),
            })
          )
        } catch (error) {
          console.log('shareCaseFile.postMessage', error)
        }
      } else {
        const text = `${window.location.origin}/transform/searchKeepAlive/precedentDetail?docid=${docid}`
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          duration: 1000,
          message: '已复制链接，粘贴发送给他人',
        })
      }
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    async download2() {
      const res = await this.$axios.get(`${caseBase}/document/legal/precedent/new/${this.id || this.pageId}/file`)
      const ua = navigator.userAgent
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line no-undef
        AND2JS.showFilePreview(res.data.data.filePath + res.data.data.fileName)
      } else if (ua === 'fb-iOS-Webview') {
        window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.filePath + res.data.data.fileName)
      } else if (ua === 'fb-flutter-Webview') {
        try {
          // 新改的方法
          window.showFilePreview.postMessage(
            JSON.stringify({
              filePath: res.data.data.filePath + res.data.data.fileName,
              fileName: res.data.data.fileName,
            })
          )
        } catch (error) {
          console.log('fbDownloadFile.postMessage', error)
        }
      } else {
        this.$axios.get(`https://api.farbun.com/document/legal/precedent/new/${this.id}/file`).then((res) => {
          // window.location = res.data.data.filePath + res.data.data.fileName
          console.log('res', res.data.data.filePath + res.data.data.fileName)
        })
      }
    },
    clearpl() {
      this.preview = false
    },
    // 收藏成功事件
    getId(id) {
      // console.log('收藏成功事件')
      if (this.$refs.tabs) {
        this.$refs.tabs.cancel()
      }
      this.data = []
      this.moduleName = ''
      this.notify('收藏成功,点击查看收藏').addEventListener(() => {
        // console.log('收藏成功点击addeventlistener')
        this.$notify.clear()
        if (document.querySelector('.menuOfDetailInCaseInAna')) {
          document.querySelector('.menuOfDetailInCaseInAna').style.display = 'none'
          this.skydriveif = true
        }
      })
    },
    //  更改样式方法
    notify(message) {
      this.$notify({
        message: message,
        background: '#07c160',
        className: 'TOSAVELIST',
      })
      return {
        addEventListener(callback) {
          setTimeout(() => {
            document.querySelector('.TOSAVELIST').onclick = callback
          })
        },
      }
    },
    showOption() {
      this.options = true
      const fn = () => {
        this.options = false
        document.body.removeEventListener('click', fn)
      }
      document.body.addEventListener('click', fn, true)
    },
    // 取消收藏方法
    close(flag) {
      console.log('取消收藏方法')
      this.haha = false
      if (flag) {
        console.log('取消if')
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      } else {
        console.log('取消else')
        this.$notify({
          background: 'rgb(25, 137, 250)',
          duration: 1000,
          message: '已取消收藏',
        })
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
        //
      }
    },
    closeReport() {
      setTimeout(() => {
        this.showReport = false
      }, 200)
    },
    closesky() {
      this.skydriveif = false
      if (document.querySelector('.menuOfDetailInCaseInAna')) {
        document.querySelector('.menuOfDetailInCaseInAna').style.display = 'block'
      }
    },
    collection() {
      this.haha = true
    },
    collectReport() {
      console.log(this.pageInfo, this.changepageInfo, 'this.pageInfo')
      console.log(caseBase, 'cccccccccccccccccccccc')
      const { above_datas: aboveDatas, basic_data: basicData, bottom_datas: bottomDatas } = this.changepageInfo
      const list = [...aboveDatas, ...basicData, ...bottomDatas]
      const content = []
      list.forEach((v) => {
        const index = content.findIndex((item) => item.name === v.name)
        if (index === -1) {
          content.push(v)
        } else {
          content[index].values.concat(v.values)
        }
      })
      const reportData = {
        doc_id: this.pageInfo.id,
        case_name: this.changepageInfo.case_name,
        content,
      }
      let selectJudgeList = []
      const selectJudgeIds = this.judgeReports.map((item) => item.doc_id)
      if (selectJudgeIds.indexOf(reportData.doc_id) !== -1) {
        this.$notify('您已经收集过这篇判例报告')
        return
      } else {
        selectJudgeList = [...this.judgeReports, reportData]
      }
      this.setStampReport({
        userId: this.userId,
        type: 'judgeReports',
        reportData: [...selectJudgeList],
        callback: () => {
          console.log('callback fn -------- 勾选判例 回调函数 --------')
        },
      })
    },
    handleCheck(obj) {
      // console.log(obj, this.contentArr, '当前页面的obj')
      if (this.contentArr && this.contentArr.length > 0) {
        const index = this.contentArr.findIndex((item) => item.id === obj.id)
        if (index !== -1) {
          this.contentArr[index] = obj
        } else {
          this.contentArr.push(obj)
        }
      } else if (this.contentArr && this.contentArr.length === 0) {
        this.contentArr.push(obj)
      }
      // this.SET_CONTENT_REPORT(JSON.parse(JSON.stringify(this.contentArr)))
      const filterArr = this.contentArr.filter((item) => {
        return item.arr.length + item.textArr.length > 0
      })
      console.log(this.contentArr, filterArr, '<<------contentArr-------')

      this.setStampReport({
        userId: this.userId,
        type: 'contentReports',
        reportData: [...JSON.parse(JSON.stringify(filterArr))],
      })
    },
    handleCollect() {
      this.isCollect = !this.isCollect
      this.SET_CLEAR_CHECK(false)
      // this.$router.push('/transform/searchReport')
      this.showReport = true
    },
    stopTouchMoveEvent(nv) {
      if (nv) {
        window.addEventListener('touchmove', this.stopTouchMove, {
          passive: false,
        })
      } else {
        window.removeEventListener('touchmove', this.stopTouchMove, {
          passive: false,
        })
      }
    },
    stopTouchMove(e) {
      e.preventDefault()
    },
    updatePageInfo(info) {
      this.changepageInfo = { ...info }
    },
    getname(name) {
      this.Title = name
    },
    liste() {
      this.collect = !this.collect
      if (this.collect) {
        const fn = () => {
          this.collect = false
          document.body.removeEventListener('click', fn)
        }
        document.body.addEventListener('click', fn, false)
      }
    },
    ...mapMutations('user', ['setUserCurrentObj']),
    clickShow() {
      this.show = !this.show
      this.is_above = !this.is_above
    },
    clickZhiding() {
      const Interval = setInterval(() => {
        if (this.$refs.detailContainer.scrollTop > 0) {
          this.$refs.detailContainer.scrollTop = this.$refs.detailContainer.scrollTop - this.$refs.detailContainer.scrollTop / 5
        } else {
          clearInterval(Interval)
        }
      }, 1)
    },
    handleScroll() {
      if (this.$refs.detailContainer.scrollTop < 100) {
        this.$refs.zhiding.style.display = 'none'
      } else {
        this.$refs.zhiding.style.display = 'block'
      }
    },
    // 点击返回
    clickback() {
      // window.document.location.href =
      //   window.location.origin + window.location.pathname
      history.replaceState(null, null, ' ')
      if (document.querySelector('.menuOfDetailInCaseInAna')) {
        document.querySelector('.menuOfDetailInCaseInAna').style.display = 'none'
      }
      if (!this.$route.query.lawid && !this.$route.query.docid) {
        if (window.document.querySelector('iframe[qc-id=page]')) {
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line
            AND2JS.back()
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            try {
              window.back.postMessage('{}')
            } catch (error) {
              console.log('back.postMessage', error)
            }
          }
          window.document.querySelector('iframe[qc-id=page]').contentWindow.goback()
          return
        }
        this.$emit('changeflag', false)
      } else if (this.$route.params.flag) {
        const ua = navigator.userAgent
        if (ua === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.back()
        } else if (ua === 'fb-flutter-Webview') {
          // eslint-disable-next-line
          try {
            window.back.postMessage('{}')
          } catch (error) {
            console.log('back.postMessage', error)
          }
        }
        this.$router.go(-1)
      } else {
        console.log('window.document.querySelector', window.document.querySelector)
        console.log('document.querySelector', document.querySelector)
        if (window.document.querySelector('iframe[qc-id=page]')) {
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line
            AND2JS.back()
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            try {
              window.back.postMessage('{}')
            } catch (error) {
              console.log('back.postMessage', error)
            }
          }
          window.document.querySelector('iframe[qc-id=page]').contentWindow.goback()
          return
        }
        window.parent.postMessage('finish', window.document.location.origin)
      }
      this.showBottom()
    },
    cancel() {
      if (this.haha) {
        this.haha = false
      } else {
        this.$emit('changeflag')
      }
    },
    // 调用原生弹出搜索框和软键盘
    clickShowSearchBar() {
      this.show = true
      this.is_above = true
      this.searchWordsShow = !this.searchWordsShow
      setTimeout(() => {
        this.$refs.searchKeyWordInput.focus()
      }, 100)
    },
    handleBlur() {
      setTimeout(() => {
        this.hiddenBottom()
      }, 500)
    },
    resetLoading(value, type) {
      this.loading = value
      this.url = window.document.location.href
      if (this.url.indexOf('#') > -1) {
        // 解除绑定
        this.url = this.url.slice(0, this.url.indexOf('#'))
        window.document.location.href = this.url
      }
      if (this.searchOpt) {
        return
      }
      if (this.$refs.content && this.$refs.content.$refs.target) {
        this.searchOpt = searchWord(this.$refs.content.$refs.target, `${this.url}`)
      }
      /* this.$nextTick(() => {
        const precedentWrapperEL = document.getElementById('precedentWrapper')
        const bodyHeight = document.body.clientHeight
        if (precedentWrapperEL && !value) {
          const pWrapperHeight = precedentWrapperEL.offsetHeight
          if (pWrapperHeight <= bodyHeight) {
            console.log(pWrapperHeight, bodyHeight, '----------')
            if (this.userId) {
              if (this.userInfo && this.userInfo.memberStatus !== 1) {
                console.log('登陆非会员滚动到底部')
                this.showLimit = true
              } else {
                this.showLimit = false
              }
            } else {
              this.showLimit = false
            }
          }
        }
      }) */
      if (this.words) {
        if (this.words.length === 1 && this.words[0] === '') {
        } else {
          setTimeout(() => {
            this.opt = this.searchOpt.findWords(this.words, this.$refs.content.$refs.target, '', type)
            // this.opt.next()
            this.opt.scroll()
            // this.closeSearch()
          }, 1000)
        }
      }
    },
  },
  created() {
    if (this.$route.query && this.$route.query.docid) {
      this.pageId = String(this.$route.query.docid).replaceAll('-', '/')
    }
    this.hiddenBottom()
    this.setUserCurrentObj(this)
    this.pageInfo.id = this.id || this.pageId
    window.showTitleBar = function () {
      this.showTitleBar = true
    }.bind(this)
  },
  watch: {
    contentReports: {
      deep: true,
      handler() {
        this.contentArr = JSON.parse(JSON.stringify(this.contentReports)) || []
      },
    },
    haha: {
      handler(val) {
        if (val) {
          if (document.querySelector('.menuOfDetailInCaseInAna')) {
            document.querySelector('.menuOfDetailInCaseInAna').style.display = 'none'
          }
        } else {
          if (document.querySelector('.menuOfDetailInCaseInAna')) {
            document.querySelector('.menuOfDetailInCaseInAna').style.display = ''
          }
        }
      },
    },
    reportCount(nv, ov) {
      this.lastCount = ov
      if (nv === ov + 1) {
        this.showAdd = true
        setTimeout(() => {
          this.showAdd = false
        }, 600)
      } else if (nv === ov - 1) {
        this.showReduce = true
        setTimeout(() => {
          this.showReduce = false
        }, 600)
      }
      // console.log(nv, ov, '监听count数量')
    },
    showLeftPanel(nv) {
      this.stopTouchMoveEvent(nv)
    },
    // $route(n, o) {
    //   const id = 'search-' + n.hash.substr(1)
    //   this.$nextTick(() => {
    //     console.log(id)
    //     document.getElementById(id).scrollIntoView()
    //     // const height = this.$refs.detailContainer.scrollHeight
    //     // this.$refs.detailContainer.scrollTo(0, height)
    //   })
    //   console.log('监听路由参数是否变化', n, o)
    // }
  },
}
</script>
<style scoped lang="stylus">
*
  margin 0px
  padding 0px
  list-style none
.precedentDetail
  width 100%
  height 100%
  overflow-x hidden
  // padding-bottom 100px
.pre
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.skyDrive
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.menu
  width 101px
  // height 86px
  box-shadow 0px 0px 7px 3px rgba(240, 240, 240, 1)
  background #ffffff
  border-radius 10px
  position absolute
  right 10px
  top calc(100% + 5px)
  div
    height 43px
    width 100%
    line-height 43px
#precedentDetail /deep/ .fb-header-title
    font-size 14px !important
#precedentDetail
  height 100vh
  overflow-y auto
  overflow-x hidden
  .van-loading
    position: fixed;
    /* z-index: 9999; */
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    /* background: rgba(0,0,0,0.1); */
    color: #409eff;
    display: flex;
    justify-content: center;
    align-items: center;
  .container
    padding-top 1.173333rem
  .CaseInAna
    position fixed
    right 25px
    bottom 55px
    display flex
    flex-direction column
    justify-content center
    align-items center
    z-index 1000
    .CaseInAnaList
      max-height 300px
      height auto
      overflow scroll
      border-radius 0
      color #fff
      background-color rgba(0, 0, 0, 0)
      opacity 0.9
      & .badge-box
        position absolute
        top -10px
        right 0
        text-align right
        color red !important
        z-index 999
        font-weight bold !important
        font-size 14px !important
      &::-webkit-scrollbar
        display none
      & .count
        font-size 16px
        line-height 16px
        position absolute
        color red
        right 3px
        top 3px
    div
      align-items center
      justify-content center
      width 45px
      height 45px
      border-radius 50%
      background-color #b6b6b6
      color #fff
      margin-top 10px
      font-size 24px
      text-align center
      span
        font-size 24px
        line-height 45px
  .jianpan
    width 100%
    position fixed
    top 93.5%
    z-index 10525
    height 48px
    display flex
    align-items center
    border 1px solid red
    background rgb(255, 255, 255)
    .right
      font-size 15px
      margin 0 10%
      // padding-right 3%
  .app-download-tip
    position fixed
    bottom 0
    left 0
    width 100%
    z-index 1001
.contents
  width 70%
  height 100%
  position absolute
  background-color white
  top 0px
  z-index 1001
  padding 10px
  transition all 0.8s
  overflow auto
  div
    font-size 14px
    padding 10px
    border-bottom 1px solid #CCCCCC
    line-height 20px
    vertical-align middle
    .iconfont
      vertical-align -0.5px
      font-size 18px !important
      float right
.search-words
  width 100%
  position fixed
  background-color white
  border solid #ccc 1px
  bottom 0px
  left 0px
  z-index 999
  padding 10px
  & .words
    display flex
    align-items center
    flex-wrap wrap
    & > div
      background #ccc
      color #FFF
      padding 0 3px
      margin-right 5px
      margin-bottom 5px
  & .input
    display flex
    align-items center
    & .van-icon
      font-size 30px
      margin-left 10px
.z_z
  width 100%
  height 100%
  position absolute
  background-color black
  top 0px
  z-index 1000
  opacity 0.5
  transition all 0.5s
.otherModule
  width 100%
  height 100%
  position fixed
  top 0px
  left 0px
  background-color white
  z-index 1001
  transition all 0.5s
  .heade
    width 100%
    height 30px
    border-bottom 1px solid #cccccc
    line-height 25px
    padding 0px 5px 0px 5px
    .text
      display inline-block
      line-height 30px
      height 30px
      float right
      margin-right 10px
      color #333
    i
      font-size 14px !important
    i:nth-child(3)
      float right
      margin-top 3px
      border-radius 5px
      position relative
      .options
        width 70px
        height 50px
        box-sizing border-box
        position absolute
        right 0px
        box-shadow 0px 0px 10px #cccccc
        border-radius 5px
        z-index 100
        background-color white
        top calc(102% + 5px)
        div
          width 100%
          height 50%
          text-align center
          line-height 22px
          color #333
    .title
      margin-left 20px
      vertical-align middle
      font-size 14px
      color #333
  .body
    width 100%
    height calc(100% - 30px)
.TOSAVELIST
  z-index 10000001
</style>
<style scoped>
.sky-drive-if {
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
  position: fixed;
}

.collect-report {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #fff;
  top: 0;
  z-index: 1000000001 !important;
  position: fixed;
}

.icon-fuzhi {
  font-size: 14px !important;
  color: #ccc;
  cursor: pointer;
  opacity: 0.5;
}

.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translate(-30px, -10px) scale(3);
  opacity: 0;
}
</style>
