var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "load" }, [
    _vm.state === 0
      ? _c(
          "div",
          { staticClass: "loaded" },
          [
            _c("van-loading", { attrs: { size: ".7rem" } }),
            _c("div", [_vm._v("加载中...")]),
          ],
          1
        )
      : _vm._e(),
    _vm.state === 1
      ? _c("div", { staticClass: "loaded" }, [
          _c("div", [_vm._v("没有更多了")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }