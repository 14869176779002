<template>
  <div class="listItem">
    <div class="ana-header">
      <span @click="clickShowDetail">
        <span class="title" :id="`detail-${listItem.name}`">{{ listItem.name }}</span>
        <span class="iconfont icon-child-off" v-if="!showDetail"></span>
        <span class="iconfont icon-child-on" v-if="showDetail"></span>
      </span>
    </div>
    <div class="content" v-show="showDetail" v-if="listItem.values">
      <div v-for="(item, index) in listItem.values" :key="index" class="a333">
        <!-- 文字 -->
        <!-- <div
          class="a4444"
          v-if="typeof item === 'string'"
          v-html="item"
          :id="index"
          @click="addClick($event)"
        >
          {{ item }}
        </div> -->
        <div class="a4444" v-if="typeof item === 'string'" :id="index">
          <textItem :item="item" :textArr="textArr" :index="index" @copy="emitCopy" @addClick="addClick" @select="handleSelectItem" />
        </div>
        <div v-else class="a5555">
          <span v-for="(item, key, index) in item" :key="index" class="a11">
            <span v-if="key === 'case_name'" v-html="item" class="a22">{{ item }}</span>
            <span v-else-if="key === 'num'" class="num">{{ item }}次</span>
            <div v-else></div>
          </span>
        </div>
      </div>
    </div>
    <div class="content" v-show="showDetail" v-if="listItem.value">
      <div v-if="typeof listItem.value === 'string'">
        {{ listItem.value }}
      </div>
      <div v-else>
        <div v-for="(item, index) in listItem.value" :key="index">
          {{ item }}
        </div>
      </div>
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <div class="show-text">
        <p>{{ showTextP }}</p>
        <span>
          {{ showText }}
        </span>
        <div v-for="(item, index) in showTextB" :key="index" class="show-text-bdiv" v-show="showModel === 1">
          <span class="show-text-bdiv-name">{{ item.name }}</span>
          <span>{{ item.value }}</span>
        </div>
      </div>
      <div v-if="clip_data.length" class="clip">
        <div v-for="(c, i) in clip_data" :key="i" @click="toLaw(c)">
          <p class="para">新 {{ c.law_title }}{{ c.title }}</p>
          <p class="clip-text">
            {{ c.text }}
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import textItem from './textItem'
import { mapState, mapGetters } from 'vuex'
import { pydataBase } from '@api-config'
export default {
  name: 'listItem',
  components: { textItem },
  props: ['listItem', 'titleName', 'nameArr'],
  data() {
    return {
      showDetail: true,
      checked: false,
      show: false,
      showText: '',
      collectionText: '',
      collectionTextArr: [],
      textArr: [],
      showTextP: '',
      showTextB: [],
      checkedAll: false,
      showModel: 0,
      clip_data: [],
    }
  },
  computed: {
    ...mapState('searchReport', ['clearCheck']),
    ...mapGetters('user', ['isVIP', 'isValid']),
  },
  methods: {
    clickShowDetail() {
      const { isMiniVip, isMiniApp } = this.$route.query
      if ((isMiniApp && isMiniVip === '1') || this.isVIP) {
        this.showDetail = !this.showDetail
      }
    },
    toLaw(item) {
      // 跳转打开法律详情,滚动到对应的条目
      this.$bus.$emit('openDetail', {
        id: item.law_id,
        type: 'law',
        title: item.title,
      })
    },
    handleSelectItem(payload) {
      const { flag, text, index } = payload
      if (flag) {
        this.collectionTextArr.push({ text, index })
      } else {
        this.collectionTextArr.forEach((ite, i) => {
          if (index === ite.index) {
            this.collectionTextArr.splice(i, 1)
          }
        })
      }
      if (this.collectionTextArr.length) {
        this.$emit('collect', {
          name: this.listItem.name,
          arr: this.collectionTextArr,
          isCancle: false,
        })
      } else {
        this.$emit('collect', {
          name: this.listItem.name,
          arr: this.collectionTextArr,
          isCancle: true,
        })
      }
    },
    emitCopy(text) {
      console.log('emit--------------->>', text)
      this.$emit('copy', text)
    },
    addClick(e) {
      // console.log(e, '加一层组件 value.target 替换成 value')
      this.showTextP = ''
      this.showTextB = []
      const value = e.target
      if (value.id === 'red') {
        console.log('点击了红色', value.dataset.lawid)
        if (value.innerText.indexOf('《') === -1) {
          const params = {
            law_id: value.dataset.lawid,
            name: value.innerText,
          }
          // 发送请求
          this.showModel = 0
          this.vanpFn(params)
          this.showTextP = params.name
        } else {
          this.vanpFnB(value.dataset.lawid)
          this.showModel = 1
          // this.showTextP = params.name
        }
      }
    },
    vanpFn(params) {
      this.$axios
        .post(`${pydataBase}/api/lawid/content/v1`, params)
        .then((res) => {
          if (res.data.code === 200 && res.data.result) {
            const { result } = res.data
            this.showText = result.text || '暂无数据'
            this.clip_data = result.relevancy
            this.show = true
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    vanpFnB(params) {
      this.$axios.get(`${pydataBase}/api/tree/item/law?law_id=${params}`).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            // data.more_info:[{}]
            this.showTextB = res.data.result
            this.show = true
          }
        }
      })
    },
    openAll() {
      this.showDetail = true
    },
    closeAll() {
      this.showDetail = false
    },
  },
  created() {
    if (this.listItem.key === 'deem') {
      this.showDetail = true
    }
    if (this.nameArr.length) {
      this.nameArr.forEach((ite) => {
        if (this.listItem.name === ite.name) {
          this.textArr = ite.arr
          this.collectionTextArr = ite.arr
        }
      })
    }
  },
  mounted() {},
  watch: {
    clearCheck(val) {
      if (val) {
        this.collectionTextArr = []
      }
    },
  },
}
</script>
<style scoped lang="stylus">
.listItem
  padding 5px 0
  .title
    font-size 16px
    font-weight 600
    color #333
  .ana-header
    display flex
    align-items center
    font-size 1.2em
    text-indent 1em
    color #666666
    .iconfont
      font-size 1.4em
      color #569AF9
      text-indent 0
  .content
    margin-top 10px
    color rgba(51, 51, 51, 1)
    font-weight 400
    font-family PingFang-SC-Regular
    font-size 15px
    line-height 28px
    box-sizing border-box
    padding 0px 20px 0px 20px
    .num
      margin-left 10px
  & .clip
    background #ececec
    padding 5px 10px
    font-size 14px
    margin-top 10px
    & .para
      margin-bottom 10px
      font-weight bold
      color #f90
      cursor pointer
</style>
<style scoped>
.show-text {
  padding: 5px;
  line-height: 100%;
  font-size: 18px;
}
.show-text > p {
  font-size: 20px;
  margin: 10px;
  margin-bottom: 25px;
}
.show-text-bdiv {
  margin: 10px 0;
}
.show-text-bdiv-name {
  width: 30%;
  display: inline-block;
}
.content .tiaomu {
  background: #d5efff;
  border-bottom: 2px dashed #66c3ff;
  font-style: normal !important;
  color: #333 !important;
  font-weight: 500 !important;
}
</style>
