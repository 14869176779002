var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appeal-content" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c("van-tab", { attrs: { title: "本院" } }),
          _c("van-tab", { attrs: { title: "上级法院" } }),
          _c("van-tab", { attrs: { title: "高级法院" } }),
          _c("van-tab", { attrs: { title: "最高法院" } }),
        ],
        1
      ),
      !_vm.watch
        ? _c(
            "div",
            { staticClass: "itemcontent", on: { scroll: _vm.loaded } },
            [
              _vm.cities.length > 0
                ? _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function ($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll",
                      },
                    },
                    [_vm._v("全部")]
                  )
                : _vm._e(),
              _vm._l(_vm.cities, function (item, index) {
                return _c("div", { key: index, staticClass: "items" }, [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: item.flag,
                          callback: function ($$v) {
                            _vm.$set(item, "flag", $$v)
                          },
                          expression: "item.flag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "right",
                      on: {
                        click: function ($event) {
                          return _vm.jump(item.doc_id)
                        },
                      },
                    },
                    [
                      _c("div", [_vm._v(_vm._s(item.case_name))]),
                      _c("div", [_vm._v(_vm._s(item.case_id))]),
                    ]
                  ),
                ])
              }),
              _c("load", { attrs: { state: _vm.fullscreenLoading } }),
            ],
            2
          )
        : _vm._e(),
      _vm.watch
        ? _c(
            "div",
            {
              staticClass: "frame",
              staticStyle: {
                "-webkit-overflow-scrolling": "touch",
                overflow: "auto",
              },
            },
            [
              _c("iframe", {
                ref: "content",
                attrs: { src: _vm.src, "qc-id": "page", frameborder: "0" },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }