import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('user', {
      userId: (state) => state.userInfo.id,
    }),
    ...mapState('user', ['userInfo']),
    ...mapState('nonmemberLimit', ['isValid']),
    isVip() {
      console.log(this.userInfo, 'this.user')
      if (this.userId) {
        console.log(this.userInfo, 'userInfo')
        return this.userInfo && this.userInfo.memberStatus === 1
      } else {
        return false
      }
    },
  },
  methods: {
    // 处理页面滚动事件滚动到非vip可查看的底部就弹框
    taggleBottomScroll(e) {
      this.showLimit = false
      const flag = (e.target.scrollTop + e.target.offsetHeight).toFixed() >= e.target.scrollHeight
      if (this.userId) {
        if (flag && this.$store.state.user.userinfo && this.$store.state.user.userinfo.memberStatus !== 1 && this.$store.state.user.userinfo.restNum <= 0 && !this.$route.query.isMiniApp) {
          console.log('登陆非会员滚动到底部', this.$store.state.user.userinfo)
          this.showLimit = true
          // this.$bus.$emit('nonmemberLimit', {
          //   dialogVisible: true,
          //   isHidden: false,
          // })
        } else {
          this.showLimit = false
        }
      } else {
        this.showLimit = false
      }
    },
  },
}
