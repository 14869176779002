<template>
  <div class="list bottom" v-if="array.length">
    <div class="firstTitle" @click="showList = !showList">
      <span :id="`detail-${title}`">{{ title }}</span>
      <span class="iconfont icon-child-off" v-if="!showList"></span>
      <span class="iconfont icon-child-on" v-if="showList"></span>
    </div>
    <div class="content" v-show="showList">
      <div class="cell" v-for="(item, index) in array" :key="index">
        <div
          v-if="typeof item === 'string'"
          v-html="filter(item)"
          @click="jump(item)"
        ></div>
        <div v-else>
          <span
            v-html="filter(item.name || item.case_name || item.title)"
            @click="jump(item.name || item.case_name)"
          ></span>
          <span class="num" v-html="item.num"></span>
        </div>
      </div>
    </div>
    <div
      v-if="newDetail"
      style="-webkit-overflow-scrolling:touch;overflow:auto;overflow:auto;"
      class="law-detail"
    >
      <iframe :src="src" qc-id="page" frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
export default {
  name: 'list',
  props: ['array', 'title', 'type', 'toZYJD'],
  data() {
    return {
      showList: false,
      newDetail: false,
      src: '',
    }
  },
  mounted() {
    console.log(this.toZYJD)
    if (this.toZYJD) {
      this.$nextTick(() => {
        console.log(document.getElementById('ZYJD'))
        document.getElementById('ZYJD').scrollIntoView()
        // const height = this.$refs.detailContainer.scrollHeight
        // this.$refs.detailContainer.scrollTo(0, height)
      })
    }
  },
  methods: {
    // 全部展开
    openAll() {
      this.showList = true
    },
    closeAll() {
      this.showList = false
    },
    getparams(html) {
      const frame = document.createElement('div')
      frame.innerHTML = html
      let temp = ''
      if (frame.length > 0) {
        temp = frame.children[0].href.slice(
          frame.children[0].href.indexOf('?') + 1
        )
      }
      return {
        params: frame.children[0] ? qs.parse(temp) : {},
        text: frame.children[0] ? frame.children[0].innerText : html,
      }
    },
    filter(html) {
      console.log(html)
      console.log(this.getparams(html).text)
      return this.getparams(html).text
    },
    jump(html) {
      if (Object.keys(this.getparams(html).params).length === 0) {
        return false
      }
      const fn = e => {
        if (e.origin === window.document.location.origin) {
          if (e.data === 'finish') {
            this.newDetail = false
            this.src = ''
          }
          window.removeEventListener('message', fn)
        }
      }
      window.addEventListener('message', fn, true)
      // eslint-disable-next-line no-undef
      this.src = `${window.document.location.origin}/search?${qs.stringify(
        this.getparams(html).params
      )}`
      this.newDetail = true
    },
  },
}
</script>
<style scoped lang="stylus">
.list
  .firstTitle
    display flex
    align-items center
    font-size 1.6em
    font-weight bold
    .iconfont
      font-size 1.4em
      color #569AF9
  .cell
    padding 5px 0
    color #666666
    padding-left 1.4em
    .num
      margin-left 10px
  .law-detail
    width 100%
    height 100%
    background-color white
    position fixed
    top 0px
    left 0px
    z-index 1000000
    iframe
      width 100%
      height 100%
</style>
