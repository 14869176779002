<template>
  <div class="list fs">
    <div class="firstTitle" @click="showListHandle">
      <span :id="`detail-${title}`">{{ title }}</span>
      <span class="iconfont icon-child-off" v-if="!showList"></span>
      <span class="iconfont icon-child-on" v-if="showList"></span>
    </div>
    <div class="content contentHeight" v-show="showList">
      <list-item
        v-for="(item, index) in array"
        ref="listItem"
        :nameArr="nameArr"
        @collect="emitCollect"
        @copy="handleCopy"
        :clearCheck="clearCheck"
        :key="item.name + index"
        v-show="(item.values && item.values.length) || (item.value && item.value.length)"
        :listItem="item"
        :titleName="titleName"
      />
    </div>
    <van-popup closeable v-model="showBuyVipPopup" round>
      <div class="popupBox">
        <img src="../../../../../../public/img/popup.png" alt="" style="position: absolute; left: 19vw; top: -80px" />
        <div class="hyzs">会员专属</div>
        <div class="kthy">开通律呗会员即可享用大数据功能！</div>
        <div class="vipBtn"><van-button block type="info" size="normal" @click="toBuyVipPage">立即开通</van-button></div>
      </div>
    </van-popup>

    <div v-if="!isShowAll">
      <div class="mask"></div>
      <div class="clickShowAllBtn" @click="showBuyVipPopup = true">点击查看剩余全文 <van-icon name="arrow-down" /></div>
    </div>
  </div>
</template>
<script>
import listItem from './listItem'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'list',
  props: ['array', 'title', 'type', 'titleName', 'currentObj'],
  components: {
    listItem,
  },
  data() {
    return {
      showList: false,
      nameArr: [], // vueX中当前页面的收集数据
      collection: [],
      currentObjTextArr: [],
      isShowAll: true,
      showBuyVipPopup: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isVIP', 'isValid']),

    ...mapState('searchReport', ['clearCheck']),
  },
  methods: {
    toBuyVipPage() {
      wx.miniProgram.redirectTo({
        url: '/subPack/common/popularize/buyVip',
      })
    },
    showListHandle() {
      const { isMiniVip, isMiniApp } = this.$route.query

      if ((isMiniApp && isMiniVip === '1') || this.isVIP) {
        this.showList = !this.showList
      }
    },
    handleCopy(text) {
      console.log('handleCopy---------->>', this.currentObjTextArr, text)
      if (this.currentObjTextArr.length <= 0) {
        this.currentObjTextArr.push(text)
      } else {
        const index = this.currentObjTextArr.indexOf(text)
        if (index === -1) {
          this.currentObjTextArr.push(text)
        }
      }
      this.$emit('collect', {
        collection: this.collection,
        textArr: this.currentObjTextArr,
      })
    },
    emitCollect(payload) {
      if (payload.isCancle) {
        if (this.collection.length) {
          this.collection.forEach((item, index) => {
            if (item.name === payload.name) {
              this.collection.splice(index, 1)
            }
          })
        }
      } else {
        const index = this.collection.findIndex((item) => {
          return item.name === payload.name
        })
        if (index === -1) {
          this.collection.push(payload)
        } else {
          this.collection[index] = payload
        }
      }
      this.$emit('collect', {
        collection: this.collection,
        textArr: this.currentObjTextArr,
      })
    },
    // 全部展开
    openAll() {
      this.showList = true
      this.$refs.listItem.forEach((element) => {
        element.openAll()
      })
    },
    closeAll() {
      this.showList = false
      this.$refs.listItem.forEach((element) => {
        element.closeAll()
      })
    },
  },
  created() {
    if (this.currentObj && this.currentObj.arr) {
      this.collection = this.currentObj.arr
      this.nameArr = this.currentObj.arr
    }
    if (this.currentObj && this.currentObj.textArr) {
      this.currentObjTextArr = this.currentObj.textArr
    }
    // console.log(this.currentObj, 'list ---- currentObj')
    if (this.type === 'above') {
      this.showList = true
    }
    const { isMiniVip, isMiniApp } = this.$route.query
    if (isMiniApp) {
      this.$nextTick(() => {
        const tim = setInterval(() => {
          if (this.array.length) {
            clearInterval(tim)
            if (isMiniVip === '0' && !this.isVIP) {
              this.isShowAll = false
              const containerHeight = document.getElementsByClassName('container')[0].offsetHeight
              const content = document.getElementsByClassName('content')[0]
              content.style.height = `${containerHeight * 0.75}px`
              content.style.overflow = 'hidden'
            }
          }
        }, 500)
      })
    }
  },
  watch: {
    clearCheck(val) {
      if (val) {
        this.collection = []
      }
    },
  },
}
</script>
<style scoped lang="stylus">
:deep(.van-popup){
  overflow-y: unset;

}
.popupBox{
  width: 349px;
  height: 361px;
  border-radius: 12px;
  background: linear-gradient(181deg, #DBECFF 0%, #FFFFFF 73%);
  position relative;
  .hyzs{
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    color: #359EFF;
    padding-top: 100px;
    text-align center
  }
  .kthy{
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.29px;
    color: #333333;
    text-align:center;
    margin-top 50px
  }
  .vipBtn{
    margin: 100px auto;
    text-align: center;
    width: 193px;
    height: 38px;
  }
}
.popupImg{
  width:179px;
  height:133px;
  margin: 0 auto;
  display: flex;
}
.list
  .firstTitle
    display flex
    align-items center
    font-size 1.6em
    font-weight bold
    .iconfont
      font-size 1.4em
      color #569AF9
.mask
  height: 3.4rem
  background linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)
  position relative
  top -3.4rem
.clickShowAllBtn
  width: 200px;
  height: 40px;
  border-radius: 4px;
  margin -110px auto
  margin-bottom 100px
  box-sizing: border-box;
  border: 1px solid #1890FF;
  font-size: 14px;
  line-height: 40px;
  text-align center
  color: #1890FF;
  cursor pointer
</style>
