<template>
  <div class="thread"
       v-if="data">
    <div class="items"
         v-for="(item,index) in data"
         :key="index"
         @click="jump(item.doc_id)"
         :style="{'border-left-color':index === data.length - 1 ? 'transparent' : ''}">
      <div class="text">
        <div>{{item.pub_date}}</div>
        <div :style="{'font-weight':item.type?'600':''}">{{item.title}}</div>
      </div>
      <div :class="[item.type ? 'cirl-big':'cirl']">{{item.type}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    jump (docid) {
      window.open(`${window.document.location.origin}/search?docid=${docid}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.thread
  width 100%
  overflow hidden
  padding 15px
  box-sizing border-box
  .items
    width 100%
    height 70px
    border-left 1px solid #CCCCCC
    position relative
    .text
      position absolute
      left 20px
      top -10px
      font-size 14px
      cursor pointer
      div:nth-child(1)
        color #666666
      div:nth-child(2)
        font-size 15px
    .cirl
      width 13px
      height 13px
      border-radius 50%
      background-color #569AF9
      position absolute
      top -6.5px
      left -6.5px
    .cirl-big
      width 25px
      height 25px
      border-radius 50%
      background-color #569AF9
      position absolute
      text-align center
      top -12.5px
      left -12.5px
      line-height 25px
      font-size 10px
      color white
</style>
