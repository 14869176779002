<template>
  <div class="load">
    <div class="loaded"
         v-if="state === 0">
      <van-loading size=".7rem"></van-loading>
      <div>加载中...</div>
    </div>
    <div class="loaded"
         v-if="state === 1">
      <div>没有更多了</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['state']
}
</script>

<style lang="stylus" scoped>
.loaded
  width 100%
  overflow hidden
  display flex
  justify-content center
  div
    align-self center
    color #666666
</style>
