<template>
  <div class="detailOfCaseInAna" ref="target">
    1
    <div v-html="tableHtml" v-if="tableHtml"></div>

    <list :array="aboveData" ref="above" title="目录" type="above" :currentObj="currentObj" :titleName="titleName" @collect="handleCollect" :clearCheck="clearCheck" v-else />
    <!-- <list :array="basic"
          title="基本信息"
          ref="basic"
          type="basic" /> -->
    <basic-list v-show="isVIP || isShow" :array="basic" title="基本信息" ref="basic" type="basic" />
    <!-- <list :array="bottom"
          ref="bottom"
          title="引用法规"
          type="bottom" /> -->
    <bottom-list v-for="(item, index) in bottom" :key="index" v-show="isVIP || isShow" ref="bottom" :title="item.name" :array="item.values" type="bottom" />
  </div>
</template>
<script>
import { pydataBase } from '~api-config'
import { mapState, mapGetters } from 'vuex'
import getScrollEventTarget from './getScrollEventTarget.js'
//  import listItem from './components/listItem'
import list from './components/list'
import basicList from './components/basicList'
import bottomList from './components/bottomList'
import axios from 'axios'
import { typeOf, deepCopy } from '@/utils/assist'
export default {
  name: 'detail',
  props: ['pageInfo', 'clearCheck', 'title', 'scrollDocument', 'inScrollMenu', 'is_above', 'words', 'titleName', 'forword', 'flag', 'toZYJD'],
  components: {
    // listItem,
    list,
    basicList,
    bottomList,
  },

  computed: {
    ...mapState('searchReport', ['contentReports']),
    ...mapState('user', {
      userId: (state) => state.userInfo.id,
    }),
    ...mapState('user', ['userInfo']),
    ...mapGetters('user', ['isVIP', 'isValid']),
    // 上部分内容
    aboveData() {
      if (typeOf(this.above) === 'array') {
        const abovedatas = deepCopy(this.above)
        const docid = this.$route.query && this.$route.query.docid
        // if (this.isVIP || this.isValid || docid || this.isShow) {
        if (docid || this.isShow) {
          return abovedatas
        } else {
          if (abovedatas && abovedatas.length > 5) {
            return abovedatas.slice(0, 3)
          } else if (abovedatas && abovedatas.length <= 5 && abovedatas.length >= 3) {
            return abovedatas.slice(0, 2)
          } else {
            return abovedatas.slice(0, 1)
          }
        }
      } else {
        return []
      }
    },
    showMenu() {
      if (!this.inScrollMenu || (this.$store.state.caseDetail.bottomTabMode !== 'MIN' && this.$store.state.caseDetail.currrentTabName === 'AnalyseTab')) {
        return true
      } else {
        return false
      }
    },
  },
  data() {
    return {
      tableHtml: '',
      // 展开内容
      basic: [],
      // 底部内容
      bottom: [],
      // 主体信息
      above: [],
      currentObj: null, // vueX中当前页面选中的数据
      textArr: [],
      menu: null,
      toTop: null,
      sacrollTarget: null,
      opened: false,
      cancel: axios.CancelToken.source(),
      isShow: false,
    }
  },
  methods: {
    handleCollect(payload) {
      const arr = [...payload.collection]
      const textArr = [...payload.textArr]
      const obj = {
        id: this.pageInfo.id,
        isLaw: false,
        title: this.title,
        arr,
        textArr,
      }
      if (obj.isCancle) {
        obj.arr = []
      }
      console.log('emit--changcheck---->>', obj)
      this.$emit('changcheck', obj)
    },
    // 回到顶层
    goBackToTop() {
      this.sacrollTarget.scrollTop = 0
    },
    // 全部展开
    openAll() {
      this.$refs.above.openAll()
      try {
        this.$refs.basic.openAll()
        this.$refs.bottom.forEach((element) => {
          element.openAll()
        })
      } catch (e) {}
    },
    // 全部闭合
    closeAll() {
      this.$refs.above.closeAll()
      try {
        this.$refs.basic.closeAll()
        this.$refs.bottom.forEach((element) => {
          element.closeAll()
        })
      } catch (e) {}
    },
    // 开合处理
    openOrClose() {
      if (this.opened) {
        this.closeAll()
        this.opened = false
      } else {
        this.openAll()
        this.opened = true
      }
    },
    goBack() {
      this.$emit('clickBack')
    },
    // 动态创建返回按钮
    createMenu(isCreated) {
      if (!this.$route.query || !this.$route.query.docid) {
        this.menu = document.createElement('div')

        this.menu.classList.add('menuOfDetailInCaseInAna')
        // const toTop = document.createElement('span')
        // this.toTop = toTop
        // toTop.classList.add('iconfont')
        // toTop.classList.add('icon-zhiding')
        // toTop.addEventListener('click', this.goBackToTop)
        // if (isCreated) {
        //   toTop.style.display = 'none'
        // }
        // const openAll = document.createElement('span')
        // openAll.classList.add('iconfont')
        // openAll.classList.add('icon-zhediexiaoguo1')
        // openAll.addEventListener('click', this.openOrClose)
        const back = document.createElement('span')
        back.classList.add('iconfont')
        back.classList.add('icon-editor-undo')
        back.addEventListener('click', this.goBack)
        // this.menu.appendChild(toTop)
        // this.menu.appendChild(openAll)
        this.menu.appendChild(back)
        document.body.appendChild(this.menu)
      }
    },
    removeMenu() {
      if (this.menu) {
        document.body.removeChild(this.menu)
        this.menu = null
        this.toTop = null
      }
    },
    addclick(value) {
      const newvalue = JSON.parse(JSON.stringify(value))
      newvalue.forEach((itema, indexa) => {
        itema.values.forEach((item, index) => {
          // console.log('遍历')
          // console.log(item, index)
          if (item.indexOf('class="lawid"' !== -1)) {
            // console.log('item')
            newvalue[indexa].values[index] = item.split('class="lawid"').join(' class="lawid" id="red"')
          }
        })
      })
      // console.log('addclick over ', newvalue)
      return newvalue
    },
    // 获取详细信息
    obtainTreeItem(id) {
      this.$emit('resetLoading', true, 'resetLoading')
      let words = ''
      if (this.words) {
        if (typeof this.words === 'string') {
          words += `&word=${this.words}`
        } else {
          this.words.forEach(function (item) {
            words += `&word=${item}`
          })
        }
      }
      if (words.length > 30) {
        words = words.substr(0, 30)
      }
      this.$http
        .get(`${pydataBase}/api/tree/item?doc_id=${encodeURIComponent(id)}&origin=1${words}${this.forword ? '&forword=' + this.forword : ''}`, { cancelToken: this.cancel.token })
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              // this.$nextTick(() => {
              if (!this.flag) {
                if (document.querySelector('.menuOfDetailInCaseInAna')) {
                  document.querySelector('.menuOfDetailInCaseInAna').style.display = 'none'
                }
                setTimeout(() => {
                  const dom = document.querySelector('.menuOfDetailInCaseInAna')
                  if (dom) {
                    dom.style.zIndex = '10000000'
                  }
                }, 1000)
              } else {
                if (document.querySelector('.menuOfDetailInCaseInAna')) {
                  document.querySelector('.menuOfDetailInCaseInAna').style.display = 'none'
                }
              }
              setTimeout(() => {
                Array.from(window.document.querySelectorAll('em')).forEach((item) => {
                  if (item.getAttribute('data-attr')) {
                    item.style.color = '#F7BA2A'
                    item.style.cursor = 'pointer'
                  } else {
                    item.style.color = 'red'
                  }
                  item.style.fontWeight = '600'
                  item.style.fontStyle = 'normal'
                })
              })
              // })
            })
            // 表格
            if (res.data.result.displayType && res.data.result.displayType === 'table') {
              this.tableHtml = res.data.result.html
            }

            this.$emit('getname', res.data.result.case_name)
            this.$emit('update:title', res.data.result.case_name)
            this.above = this.addclick(res.data.result.above_datas)
            this.basic = res.data.result.basic_data
            const head = this.above.find((r) => r.key === 'head')
            if (head) {
              head.values.push(this.basic.find((r) => r.name === '裁判时间').value)
            }
            // this.above
            //   .find((r) => r.key === 'head')
            //   .values.push(this.basic.find((r) => r.name === '裁判时间').value)
            this.bottom = res.data.result.bottom_datas
            console.log('item接口返参', res.data.result.above_datas, this.basic)
            this.$emit('resetFinished', false)
            this.$emit('resetLoading', false, 'resetLoading')
            this.$emit('updatePageInfo', res.data.result)
            this.createMenu(true)
          }
        })
        .catch((err) => {
          if (this.$axios.isCancel(err)) {
            console.log(err.message)
          } else {
            this.$emit('resetLoading', false, 'resetLoading')
            this.createMenu()
            Promise.reject(err)
          }
        })
    },
  },
  created() {
    // eslint-disable-next-line camelcase
    const new_userinfo = this.$store.state.user.userinfo
    // window.console.log('最新的数据', this.$store.state.user.userinfo)
    if (new_userinfo?.restNum > 0 || new_userinfo?.memberStatus === 1 || new_userinfo?.memberStatus === '1' || JSON.parse(this.$route.query.isMiniVip) === 1) {
      this.isShow = true
    } else {
      this.isShow = false
    }
    // this.GET_COLLECT_TEXT()
    if (!this.inScrollMenu) {
      this.$nextTick(() => {
        this.sacrollTarget = getScrollEventTarget.getScrollEventTarget(this.$refs.target)
        this.sacrollTarget.onscroll = (e) => {
          if (!this.toTop) {
            return
          }
          if (this.sacrollTarget.scrollTop > 50) {
            this.toTop.style.display = 'flex'
          } else {
            this.toTop.style.display = 'none'
          }
        }
      })
    }
    this.$bus.$on('obtainRound', (id) => {
      // console.log(id, 1098)
      this.obtainTreeItem(id)
    })
    if (this.contentReports && this.contentReports.length) {
      const index = this.contentReports.findIndex((item) => item.id === this.pageInfo.id)
      if (index === -1) {
        this.currentObj = null
        this.textArr = []
      } else {
        this.currentObj = this.contentReports[index]
        this.textArr = this.currentObj.textArr
      }
    }
  },
  destroyed() {
    this.removeMenu()
    this.cancel.cancel('取消请求')
  },
  watch: {
    showMenu(value) {
      if (value) {
        this.createMenu()
      } else {
        this.removeMenu()
      }
    },
    is_above(val) {
      if (val) {
        this.openAll()
      } else {
        this.closeAll()
      }
    },
    pageInfo: {
      deep: true,
      handler: function (nv) {
        if (nv && nv.id) {
          this.obtainTreeItem(nv.id)
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="stylus">
td
  border 1px solid #CCCCCC
.detailOfCaseInAna
  font-size 13px
  padding 10px
  position relative
.menuOfDetailInCaseInAna
  position fixed
  right 25px
  bottom 10px
  display flex
  flex-direction column
  justify-content center
  align-items center
  z-index 1000
  span
    display flex
    align-items center
    justify-content center
    width 45px
    height 45px
    border-radius 50%
    background-color #B6B6B6
    color white
    margin-bottom 10px
    font-size 24px
</style>
