var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderArray.length
    ? _c("div", { staticClass: "list base" }, [
        _c(
          "div",
          {
            staticClass: "firstTitle",
            on: {
              click: function ($event) {
                _vm.showList = !_vm.showList
              },
            },
          },
          [
            _c("span", { attrs: { id: `detail-${_vm.title}` } }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            !_vm.showList
              ? _c("span", { staticClass: "iconfont icon-child-off" })
              : _vm._e(),
            _vm.showList
              ? _c("span", { staticClass: "iconfont icon-child-on" })
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showList,
                expression: "showList",
              },
            ],
            staticClass: "content",
          },
          _vm._l(_vm.renderArray, function (item, index) {
            return _c("div", { key: index, staticClass: "cell" }, [
              _c("div", [_vm._v(_vm._s(item.name) + "：")]),
              typeof item.value === "string"
                ? _c("div", { domProps: { innerHTML: _vm._s(item.value) } })
                : _c(
                    "div",
                    _vm._l(item.value, function (detail, detailIndex) {
                      return _c("div", {
                        key: detailIndex,
                        domProps: { innerHTML: _vm._s(detail) },
                      })
                    }),
                    0
                  ),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }