var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.array.length
    ? _c("div", { staticClass: "list bottom" }, [
        _c(
          "div",
          {
            staticClass: "firstTitle",
            on: {
              click: function ($event) {
                _vm.showList = !_vm.showList
              },
            },
          },
          [
            _c("span", { attrs: { id: `detail-${_vm.title}` } }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            !_vm.showList
              ? _c("span", { staticClass: "iconfont icon-child-off" })
              : _vm._e(),
            _vm.showList
              ? _c("span", { staticClass: "iconfont icon-child-on" })
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showList,
                expression: "showList",
              },
            ],
            staticClass: "content",
          },
          _vm._l(_vm.array, function (item, index) {
            return _c("div", { key: index, staticClass: "cell" }, [
              typeof item === "string"
                ? _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.filter(item)) },
                    on: {
                      click: function ($event) {
                        return _vm.jump(item)
                      },
                    },
                  })
                : _c("div", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.filter(item.name || item.case_name || item.title)
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.jump(item.name || item.case_name)
                        },
                      },
                    }),
                    _c("span", {
                      staticClass: "num",
                      domProps: { innerHTML: _vm._s(item.num) },
                    }),
                  ]),
            ])
          }),
          0
        ),
        _vm.newDetail
          ? _c(
              "div",
              {
                staticClass: "law-detail",
                staticStyle: {
                  "-webkit-overflow-scrolling": "touch",
                  overflow: "auto",
                },
              },
              [
                _c("iframe", {
                  attrs: { src: _vm.src, "qc-id": "page", frameborder: "0" },
                }),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }