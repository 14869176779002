var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "target", staticClass: "detailOfCaseInAna" },
    [
      _vm._v(" 1 "),
      _vm.tableHtml
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.tableHtml) } })
        : _c("list", {
            ref: "above",
            attrs: {
              array: _vm.aboveData,
              title: "目录",
              type: "above",
              currentObj: _vm.currentObj,
              titleName: _vm.titleName,
              clearCheck: _vm.clearCheck,
            },
            on: { collect: _vm.handleCollect },
          }),
      _c("basic-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isVIP || _vm.isShow,
            expression: "isVIP || isShow",
          },
        ],
        ref: "basic",
        attrs: { array: _vm.basic, title: "基本信息", type: "basic" },
      }),
      _vm._l(_vm.bottom, function (item, index) {
        return _c("bottom-list", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVIP || _vm.isShow,
              expression: "isVIP || isShow",
            },
          ],
          key: index,
          ref: "bottom",
          refInFor: true,
          attrs: { title: item.name, array: item.values, type: "bottom" },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }