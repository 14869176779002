var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listItem" },
    [
      _c("div", { staticClass: "ana-header" }, [
        _c("span", { on: { click: _vm.clickShowDetail } }, [
          _c(
            "span",
            {
              staticClass: "title",
              attrs: { id: `detail-${_vm.listItem.name}` },
            },
            [_vm._v(_vm._s(_vm.listItem.name))]
          ),
          !_vm.showDetail
            ? _c("span", { staticClass: "iconfont icon-child-off" })
            : _vm._e(),
          _vm.showDetail
            ? _c("span", { staticClass: "iconfont icon-child-on" })
            : _vm._e(),
        ]),
      ]),
      _vm.listItem.values
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDetail,
                  expression: "showDetail",
                },
              ],
              staticClass: "content",
            },
            _vm._l(_vm.listItem.values, function (item, index) {
              return _c("div", { key: index, staticClass: "a333" }, [
                typeof item === "string"
                  ? _c(
                      "div",
                      { staticClass: "a4444", attrs: { id: index } },
                      [
                        _c("textItem", {
                          attrs: {
                            item: item,
                            textArr: _vm.textArr,
                            index: index,
                          },
                          on: {
                            copy: _vm.emitCopy,
                            addClick: _vm.addClick,
                            select: _vm.handleSelectItem,
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "a5555" },
                      _vm._l(item, function (item, key, index) {
                        return _c("span", { key: index, staticClass: "a11" }, [
                          key === "case_name"
                            ? _c(
                                "span",
                                {
                                  staticClass: "a22",
                                  domProps: { innerHTML: _vm._s(item) },
                                },
                                [_vm._v(_vm._s(item))]
                              )
                            : key === "num"
                            ? _c("span", { staticClass: "num" }, [
                                _vm._v(_vm._s(item) + "次"),
                              ])
                            : _c("div"),
                        ])
                      }),
                      0
                    ),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.listItem.value
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDetail,
                  expression: "showDetail",
                },
              ],
              staticClass: "content",
            },
            [
              typeof _vm.listItem.value === "string"
                ? _c("div", [_vm._v(" " + _vm._s(_vm.listItem.value) + " ")])
                : _c(
                    "div",
                    _vm._l(_vm.listItem.value, function (item, index) {
                      return _c("div", { key: index }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    0
                  ),
            ]
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          style: { height: "30%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "show-text" },
            [
              _c("p", [_vm._v(_vm._s(_vm.showTextP))]),
              _c("span", [_vm._v(" " + _vm._s(_vm.showText) + " ")]),
              _vm._l(_vm.showTextB, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showModel === 1,
                        expression: "showModel === 1",
                      },
                    ],
                    key: index,
                    staticClass: "show-text-bdiv",
                  },
                  [
                    _c("span", { staticClass: "show-text-bdiv-name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                )
              }),
            ],
            2
          ),
          _vm.clip_data.length
            ? _c(
                "div",
                { staticClass: "clip" },
                _vm._l(_vm.clip_data, function (c, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      on: {
                        click: function ($event) {
                          return _vm.toLaw(c)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "para" }, [
                        _vm._v("新 " + _vm._s(c.law_title) + _vm._s(c.title)),
                      ]),
                      _c("p", { staticClass: "clip-text" }, [
                        _vm._v(" " + _vm._s(c.text) + " "),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }