var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "detailContainer",
      staticClass: "precedentDetail highlight-area",
      attrs: { id: "precedentDetail" },
      on: { scroll: _vm.taggleBottomScroll },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.skydriveif,
              expression: "!skydriveif",
            },
          ],
          attrs: { id: "precedentWrapper" },
        },
        [
          _vm.showTitleBar
            ? _c(
                "fb-header",
                {
                  attrs: {
                    slot: "header",
                    customer: true,
                    title: _vm.title,
                    fixed: "",
                  },
                  on: { clickTitle: _vm.clickTitle },
                  slot: "header",
                },
                [
                  !this.$route.query ||
                  !this.$route.query.docid ||
                  (this.$route.query && this.$route.query.back)
                    ? _c(
                        "fb-button",
                        {
                          attrs: {
                            slot: "left",
                            icon: "back",
                            size: "small",
                            type: "primary",
                          },
                          on: { click: _vm.clickback },
                          slot: "left",
                        },
                        [_vm._v(" 返回 ")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "copy",
                      staticStyle: { flex: "1" },
                      attrs: { slot: "copy" },
                      slot: "copy",
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showCopy,
                            expression: "showCopy",
                          },
                        ],
                        staticClass: "iconfont icon-fuzhi",
                        on: { click: _vm.copyTitle },
                      }),
                    ]
                  ),
                  _c(
                    "fb-button",
                    {
                      attrs: {
                        slot: "right",
                        icon: "menu",
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        "!click": function ($event) {
                          $event.stopPropagation()
                          return _vm.liste.apply(null, arguments)
                        },
                      },
                      slot: "right",
                    },
                    [
                      [
                        _vm.collect
                          ? _c("div", { ref: "politre", staticClass: "menu" }, [
                              _c("div", { on: { click: _vm.download } }, [
                                _vm._v("分享"),
                              ]),
                              !this.$route.query || !this.$route.query.docid
                                ? _c("div", { on: { click: _vm.collection } }, [
                                    _vm._v("收藏"),
                                  ])
                                : _vm._e(),
                              _vm.ua !== "fb-iOS-Webview" &&
                              (!this.$route.query ||
                                !this.$route.query.docid) &&
                              !_vm.$route.query.isMiniApp
                                ? _c("div", { on: { click: _vm.download2 } }, [
                                    _vm._v("下载"),
                                  ])
                                : _vm._e(),
                              _vm.userId
                                ? _c(
                                    "div",
                                    { on: { click: _vm.collectReport } },
                                    [_vm._v("收集")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.loadPage || _vm.loading
            ? _c("van-loading", { attrs: { type: "spinner", size: "70px" } })
            : _vm._e(),
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "div",
                { ref: "detailContainer", staticClass: "container" },
                [
                  _c("detail", {
                    ref: "content",
                    attrs: {
                      pageInfo: _vm.pageInfo,
                      words: _vm.words,
                      flag: _vm.haha,
                      forword: _vm.forword,
                      titleName: _vm.titleName,
                      title: _vm.title,
                      toCatloag: _vm.toCatloag,
                      scrollDocument: _vm.$refs.detailContainer,
                      is_above: _vm.is_above,
                      toZYJD: _vm.toZYJD,
                    },
                    on: {
                      changcheck: _vm.handleCheck,
                      "update:title": function ($event) {
                        _vm.title = $event
                      },
                      "update:toCatloag": function ($event) {
                        _vm.toCatloag = $event
                      },
                      "update:to-catloag": function ($event) {
                        _vm.toCatloag = $event
                      },
                      clickBack: _vm.clickback,
                      resetLoading: _vm.resetLoading,
                      getname: _vm.getname,
                      updatePageInfo: _vm.updatePageInfo,
                    },
                  }),
                  _c("bottom-tip", {
                    attrs: {
                      visible: _vm.bottomTipVisible,
                      showLimit: _vm.showLimit,
                    },
                    on: {
                      "update:showLimit": function ($event) {
                        _vm.showLimit = $event
                      },
                      "update:show-limit": function ($event) {
                        _vm.showLimit = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          !_vm.haha
            ? _c("div", { staticClass: "CaseInAna" }, [
                _c(
                  "div",
                  {
                    ref: "zhiding",
                    staticClass: "iconfonts zhiding",
                    staticStyle: { display: "none" },
                    on: { click: _vm.clickZhiding },
                  },
                  [_c("span", { staticClass: "iconfont icon-zhiding" })]
                ),
                _c(
                  "div",
                  { staticClass: "CaseInAnaList" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.reportCount,
                            expression: "reportCount",
                          },
                        ],
                        staticClass: "iconfonts collect",
                        style: {
                          background: _vm.isCollect ? "#f4a83b" : "",
                          position: "relative",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleCollect()
                          },
                        },
                      },
                      [
                        _c("transition", { attrs: { name: "slide-fade" } }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showAdd,
                                  expression: "showAdd",
                                },
                              ],
                              staticClass: "badge-box",
                            },
                            [_vm._v(" +1 ")]
                          ),
                        ]),
                        _c("transition", { attrs: { name: "slide-fade" } }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showReduce,
                                  expression: "showReduce",
                                },
                              ],
                              staticClass: "badge-box",
                            },
                            [_vm._v(" -1 ")]
                          ),
                        ]),
                        _c("span", [_vm._v("集")]),
                        _c("span", { staticClass: "count" }, [
                          _vm._v(_vm._s(this.reportCount)),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(_vm.approvalData, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "iconfonts mulu",
                          style: {
                            background:
                              item.doc_id === _vm.doc_id ? "#f4a83b" : "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.obtainRound(item.doc_id)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "iconfont" }, [
                            _vm._v(_vm._s(item.round.substr(0, 1))),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "iconfonts mulu",
                    on: { click: _vm.clickShow },
                  },
                  [_c("span", { staticClass: "iconfont icon-zhediexiaoguo1" })]
                ),
                !_vm.searchWordsShow
                  ? _c(
                      "div",
                      {
                        staticClass: "iconfonts fanhui",
                        on: { click: _vm.clickShowSearchBar },
                      },
                      [_c("span", { staticClass: "iconfont icon-sousuo" })]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "iconfonts fanhui",
                        staticStyle: { visibility: "hidden" },
                      },
                      [_c("span", { staticClass: "iconfont icon-sousuo" })]
                    ),
              ])
            : _vm._e(),
          _vm.haha
            ? _c(
                "div",
                { staticClass: "skyDrive" },
                [
                  _c("skyDrive", {
                    attrs: {
                      listid: _vm.data.length > 0 ? _vm.data : _vm.id,
                      Type: _vm.name
                        ? _vm.name === "relelaw"
                          ? "LAW_FRAGMENT"
                          : "LEGAL_PRECEDENT_FRAGMENT"
                        : "docid",
                      listname: _vm.Title,
                      modulename: _vm.Title,
                    },
                    on: { CancelCollect: _vm.close, getId: _vm.getId },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchWordsShow,
                  expression: "searchWordsShow",
                },
              ],
              staticClass: "search-words",
            },
            [
              _c(
                "div",
                { staticClass: "words" },
                _vm._l(_vm.wordsList, function (item, i) {
                  return _c("div", { key: i }, [
                    _vm._v(_vm._s(item)),
                    _c("i", {
                      staticClass: "iconfont icon-clear",
                      on: {
                        click: function ($event) {
                          return _vm.deleteWord(i)
                        },
                      },
                    }),
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#409eff", "margin-right": "10px" },
                      on: { click: _vm.closeSearch },
                    },
                    [_vm._v("退出")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c("van-field", {
                        ref: "searchKeyWordInput",
                        attrs: { placeholder: "请输入关键词" },
                        on: {
                          input: _vm.handleChange,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.hanldeKeyEnter.apply(null, arguments)
                          },
                          blur: _vm.handleBlur,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.hanldeKeySpace.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { margin: "0 5px" } }, [
                    _c("div", { staticStyle: { color: "#409eff" } }, [
                      _vm._v(_vm._s(_vm.currentPos)),
                    ]),
                    _c("div", { staticStyle: { color: "#ccc" } }, [
                      _vm._v(_vm._s(_vm.times)),
                    ]),
                  ]),
                  _c("van-icon", {
                    attrs: { name: "arrow-down" },
                    on: { click: _vm.optNext },
                  }),
                  _c("van-icon", {
                    attrs: { name: "arrow-up" },
                    on: { click: _vm.optPrev },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "contents",
              style: { right: _vm.showLeftPanel ? "0px" : "-100%" },
            },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.name = "likeappeal"
                    },
                  },
                },
                [
                  _c("span", [_vm._v("相似判例")]),
                  _c("i", { staticClass: "iconfont icon-child-off" }),
                ]
              ),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.name = "relelaw"
                    },
                  },
                },
                [
                  _c("span", [_vm._v("相关法律法规")]),
                  _c("i", { staticClass: "iconfont icon-child-off" }),
                ]
              ),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.name = "releappeal"
                    },
                  },
                },
                [
                  _c("span", [_vm._v("相关判例")]),
                  _c("i", { staticClass: "iconfont icon-child-off" }),
                ]
              ),
              _c("flow"),
            ],
            1
          ),
          _c("div", {
            staticClass: "z_z",
            style: { left: _vm.showLeftPanel ? "0px" : "-100%" },
            on: {
              click: function ($event) {
                _vm.showLeftPanel = false
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "otherModule",
              style: { left: _vm.name.length > 0 ? "0px" : "-100%" },
            },
            [
              _c("div", { staticClass: "heade" }, [
                _c("i", {
                  staticClass: "iconfont icon-back",
                  on: {
                    click: function ($event) {
                      _vm.name = ""
                    },
                  },
                }),
                _c("span", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.name === "flows"
                        ? "案件进程"
                        : _vm.name === "relelaw"
                        ? "相关法律法规"
                        : _vm.name === "likeappeal"
                        ? "相似判例"
                        : "相关判例"
                    )
                  ),
                ]),
                !this.$route.query || !this.$route.query.docid
                  ? _c(
                      "div",
                      { staticClass: "text", on: { click: _vm.save } },
                      [_vm._v("收藏")]
                    )
                  : _vm._e(),
                !this.$route.query || !this.$route.query.docid
                  ? _c(
                      "div",
                      { staticClass: "text", on: { click: _vm.share } },
                      [_vm._v("分享")]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c(_vm.name, {
                    ref: "tabs",
                    tag: "component",
                    attrs: { docid: _vm.id },
                    on: { getModuleName: _vm.setName },
                    model: {
                      value: _vm.data,
                      callback: function ($$v) {
                        _vm.data = $$v
                      },
                      expression: "data",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.skydriveif
        ? _c(
            "div",
            { staticClass: "sky-drive-if" },
            [
              _c("sky-drive", {
                attrs: { skydriveif: _vm.skydriveif },
                on: { close: _vm.closesky },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("fb-nonmember", {
        attrs: { showLimit: _vm.showLimit },
        on: {
          "update:showLimit": function ($event) {
            _vm.showLimit = $event
          },
          "update:show-limit": function ($event) {
            _vm.showLimit = $event
          },
        },
      }),
      _vm.isPublicRouteDetail
        ? _c(
            "div",
            { staticClass: "app-download-tip" },
            [_c("download-app")],
            1
          )
        : _vm._e(),
      _vm.showReport
        ? _c(
            "div",
            { staticClass: "collect-report" },
            [
              _c("searchReport", {
                attrs: { from: "precedent" },
                on: { close: _vm.closeReport },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }